import React, { useEffect, useState } from "react";
import { CheckCircleIcon, ChevronDownIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";

import { shouldDisplayField } from "../utils/form-utils";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const StepsOverview = ({
	steps,
	currentStep,
	setCurrentStep,
	filledFields,
	setFormIsCompleted,
}) => {
	const [internalSteps, setInternalSteps] = useState([]);
	const [isCollapsed, setIsCollapsed] = useState(true);

	const computeInternalSteps = () => {
		return steps.map((step) => {
			const isCompleted = step.fields.every((field) => {
				// If it's a spacer or subtitle, treat it as filled
				if (field.type === "subtitle" || field.type === "spacer")
					return true;

				if (field.required === false) return true;

				// If the field has a condition and shouldn't be displayed, treat it as filled
				const display = shouldDisplayField(
					field.condition,
					filledFields
				);
				if (!display) return true;

				// Check if the field exists in filledFields and it's not empty
				return (
					filledFields.hasOwnProperty(field.name) &&
					filledFields[field.name] !== ""
				);
			});
			return { title: step.title, isCompleted };
		});
	};

	useEffect(() => {
		const newInternalSteps = computeInternalSteps();
		setInternalSteps(newInternalSteps);
		// Check if all steps are completed and set formIsCompleted accordingly
		if (newInternalSteps.every((step) => step.isCompleted)) {
			setFormIsCompleted(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filledFields, setFormIsCompleted, steps]);

	if (internalSteps.length === 0) return <LoadingSpinner />;
	return (
		<>
			<div className="lg:basis-1/5 basis-full space-y-4 pt-16 hidden lg:block pr-1">
				{steps.map((step, index) => (
					<div
						key={index}
						className={
							internalSteps[index].isCompleted
								? "text-green-600 font-medium flex cursor-pointer hover:text-green-700"
								: currentStep === index + 1
								? "text-black cursor-default font-semibold"
								: "text-gray-600 cursor-pointer"
						}
						onClick={() => {
							setCurrentStep(index + 1);
							let url = new URL(window.location.href);
							url.searchParams.set("stap", index + 1);
							window.history.pushState({}, "", url.toString());
						}}
					>
					<div className="flex items-center">
						{internalSteps[index].isCompleted && (
							<CheckCircleIcon className="w-5 mr-1.5" />
						)}
						{!internalSteps[index].isCompleted && index < currentStep - 1 && (
							<ExclamationCircleIcon className="w-5 mr-2 text-orange-500" />
						)}
						
						{step.title}
						
						</div>
					</div>
				))}
			</div>
			<div className="lg:basis-1/5 basis-full space-y-4 pt-2 lg:pt-16 pb-8 lg:pb-0 lg:hidden">
				<div
					className="text-black cursor-pointer flex items-center font-semibold center"
					onClick={() => setIsCollapsed(!isCollapsed)}
				>
					<span className="mr-2">
						Stap {currentStep} van de {steps.length}
					</span>
					<ChevronDownIcon
						className={`w-5 transition-transform ${
							isCollapsed ? "rotate-0" : "rotate-180"
						}`}
					/>
				</div>
				{!isCollapsed &&
					steps.map((step, index) => (
						<div
							key={index}
							className={
								internalSteps[index].isCompleted
									? "text-green-600 font-medium flex cursor-pointer hover:text-green-700"
									: currentStep === index + 1
									? "text-black cursor-default font-semibold"
									: "text-gray-600 cursor-pointer"
							}
							onClick={() => {
								setCurrentStep(index + 1);
								let url = new URL(window.location.href);
								url.searchParams.set("stap", index + 1);
								window.history.pushState(
									{},
									"",
									url.toString()
								);
							}}
						>
							{internalSteps[index].isCompleted && (
								<CheckCircleIcon className="w-5 mr-1.5" />
							)}
							{!internalSteps[index].isCompleted && index < currentStep - 1 && (
								<ExclamationCircleIcon className="w-5 mr-1.5 text-red-500" />
							)}
							{step.title}
						</div>
					))}
			</div>
		</>
	);
};

export default StepsOverview;
