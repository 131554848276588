import React from "react";
import { Tooltip } from "react-tooltip";
import mixpanel from "mixpanel-browser";

import Input from "../../shared/components/FormElements/Input";
import Textarea from "../../shared/components/FormElements/Textarea";
import RadioGroup from "../../shared/components/FormElements/RadioGroup";
import Select from "../../shared/components/FormElements/Select";
import Subtitle from "../../shared/components/FormElements/Subtitle";
import Spacer from "../../shared/components/FormElements/Spacer";
import { shouldDisplayField } from "../utils/form-utils";
import CheckboxGroup from "../../shared/components/FormElements/CheckboxGroup";
import FieldTip from "./FieldTip";

const FieldRenderer = ({ field, values, userId, fileType }) => {
	const renderWithTooltip = (text, tooltip) => {
		if (!text || !tooltip) return text;

		const parts = text.split(tooltip.term);

		if (parts.length < 2) return text; // Return original text if term not found

		return (
			<div className="pb-1.5">
				{parts[0]}
				<span
					data-tooltip-id={tooltip.term}
					data-tooltip-place="bottom"
					className="cursor-help border-b-3 pb-0.5 border-dotted border-gray-400"
					data-tooltip-html={tooltip.explanation}
					onMouseEnter={() =>
						mixpanel.track("🏷 Tooltip getoond", {
							distinct_id: userId,
							tooltip: tooltip.term,
							explanation: tooltip.explanation,
						})
					}
				>
					{tooltip.term}
				</span>
				{parts[1]}
				<Tooltip
					id={tooltip.term}
					className="__react_component_tooltip z-50"
				/>
			</div>
		);
	};

	const display = shouldDisplayField(field.condition, values);
	if (!display) return null;

	switch (field.type) {
		case "radio":
			return (
				<>
					<RadioGroup
						key={field.name}
						name={field.name}
						label={renderWithTooltip(field.label, field.tooltip)}
						description={renderWithTooltip(
							field.description,
							field.tooltip
						)}
						options={field.options}
						required={field.required || true}
						width={!field.tip && field.width}
						includeOther={field.includeOther}
					/>
					{field.tip && (
						<>
							<div className="w-full"></div>
							<FieldTip
								question={field.label}
								preview={field.tip.preview}
								fullText={field.tip.fullText}
								userId={userId}
								fileType={fileType}
							/>
						</>
					)}
				</>
			);
		case "select":
			return (
				<>
					<Select
						key={field.name}
						name={field.name}
						label={renderWithTooltip(field.label, field.tooltip)}
						description={field.description}
						options={field.options}
						required={field.required || true}
						width={!field.tip && field.width}
					/>
					{field.tip && (
						<>
							<div className="w-full"></div>
							<FieldTip
								question={field.label}
								preview={field.tip.preview}
								fullText={field.tip.fullText}
								userId={userId}
								fileType={fileType}
							/>
						</>
					)}
				</>
			);
		case "subtitle":
			return (
				<>
					<Subtitle
						title={field.label}
						description={field.description}
					/>
					{field.tip && (
						<FieldTip
							question={field.label}
							preview={field.tip.preview}
							fullText={field.tip.fullText}
							userId={userId}
							fileType={fileType}
						/>
					)}
				</>
			);
		case "spacer":
			return (
				<>
					<Spacer />
					{field.tip && (
						<FieldTip
							question={field.label}
							preview={field.tip.preview}
							fullText={field.tip.fullText}
							userId={userId}
							fileType={fileType}
						/>
					)}
				</>
			);
		case "textarea":
			return (
				<>
					<Textarea
						key={field.name}
						name={field.name}
						label={renderWithTooltip(field.label, field.tooltip)}
						placeholder={field.placeholder}
						required={field.required || true}
						description={field.description}
						width={!field.tip && field.width}
					/>
					{field.tip && (
						<FieldTip
							question={field.label}
							preview={field.tip.preview}
							fullText={field.tip.fullText}
							userId={userId}
							fileType={fileType}
						/>
					)}
				</>
			);
		case "checkbox":
			return (
				<>
					<CheckboxGroup
						key={field.name}
						name={field.name}
						label={renderWithTooltip(field.label, field.tooltip)}
						description={field.description}
						options={field.options}
						required={field.required || true}
						width={!field.tip && field.width}
					/>
					{field.tip && (
						<FieldTip
							question={field.label}
							preview={field.tip.preview}
							fullText={field.tip.fullText}
							userId={userId}
							fileType={fileType}
						/>
					)}
				</>
			);
		default:
			return (
				<>
					<Input
						key={field.name}
						name={field.name}
						label={renderWithTooltip(field.label, field.tooltip)}
						type={field.type || "text"}
						placeholder={field.placeholder}
						required={field.required || true}
						description={field.description}
						width={!field.tip && field.width}
						prefix={field.prefix}
						suffix={field.suffix}
					/>
					{field.tip && (
						<>
							<div className="w-full"></div>
							<FieldTip
								question={field.label}
								preview={field.tip.preview}
								fullText={field.tip.fullText}
								userId={userId}
								fileType={fileType}
							/>
						</>
					)}
				</>
			);
	}
};

export default FieldRenderer;
