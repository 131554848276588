import React, { useState, useEffect } from "react";

import { useUser } from "@clerk/clerk-react";

import Container from "../components/UIElements/Container";
import Heading from "../components/UIElements/Heading";
import FileList from "../../files/components/FileList";
import Card from "../components/UIElements/Card";
import RequestNewType from "../../files/components/RequestNewType";

import wavingEmoji from "../../img/WavingEmoji.png";
import NPSMeter from "../../tevredenheid/components/NPSMeter";
import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";
import LoadingSpinner from "../components/UIElements/LoadingSpinner";
import { SparklesIcon, DocumentIcon } from "@heroicons/react/20/solid";

const Dashboard = () => {
	const [nps, setNPS] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const { user } = useUser();
	const fetchNPS = useFetch();

	useEffect(() => {
		const getNPS = async () => {
			const npsResponse = await fetchNPS(
				`/api/tevredenheid/nps/${user.id}`
			);

			if (npsResponse.totalResponses === 0) {
				npsResponse.score = "--";
			}

			setIsLoading(false);
			setNPS(npsResponse);
		};

		getNPS();
	}, [fetchNPS, user.id]);

	return (
		<Container>
			<Heading className="flex">
				Welkom, {user.firstName}
				<img src={wavingEmoji} className="w-8 ml-3" alt="" />
			</Heading>
			<div className="xl:flex pt-8">
				<div className="xl:w-2/5">
					<div className="mb-10">
						<Link to="/teamtevredenheid">
							<div className="px-6 py-5 mt-4 bg-slate-200 rounded-3xl hover:bg-[#dbe2eb] transition-all">
								{isLoading && <LoadingSpinner />}
								{!isLoading && (
									<>
										<div className="scale-75 text-left flex -ml-10">
											<NPSMeter score={nps.score} />
										</div>
										{nps.totalResponses === 0 && (
											<p className="pl-3 text-gray-600 text-sm pb-2">
												Je hebt nog geen
												teamtevredenheidsonderzoek
												uitgezet
											</p>
										)}
										{nps.totalResponses !== 0 && (
											<p className="pl-3 text-gray-600 text-sm pb-2">
												{nps.totalResponses} teamleden
												hebben meegedaan aan het
												onderzoek
											</p>
										)}
									</>
								)}
							</div>
						</Link>
						{nps.totalResponses > 4 && (
							<Link to="/teamtevredenheid/#ai">
								<p className="font-semibold mt-4 flex gradientText  hover:opacity-75 transition-opacity duration-200 ph-autocapture">
									<SparklesIcon className="h-5 w-5 mr-1.5 pt-0.5 text-[#F03D59]" />
									Bekijk AI-verbeterplan
								</p>
							</Link>
						)}
					</div>
					<div>
						<Heading size="2" className="pb-4">
							Nieuw document
						</Heading>

						{/* <Card
							title="Overeenkomst van opdracht"
							description="Voor ZZP'ers"
							link="/nieuw/ovo/"
						/> */}
						<Card
							title="Nulurencontract"
							description="Voor tijdelijke oproepkrachten"
							link="/nieuw/contract/?duur=nuluren"
						/>
						<Card
							title="Bepaalde tijd"
							description="Voor contracten van een vaste duur"
							link="/nieuw/contract/?duur=bepaald"
						/>
						<Card
							title="Onbepaalde tijd"
							description="Voor contracten zonder einddatum"
							link="/nieuw/contract/?duur=onbepaald"
						/>
						<Card
							title="Personeelsreglement"
							link="/nieuw/personeelsreglement/"
						/>
						<RequestNewType />
					</div>
				</div>
				<div className="xl:w-3/5 xl:pl-16 pt-8 xl:pt-0">
					<div className="pb-12">
						<Heading size="2">Personeelsreglement</Heading>
						<FileList limit="1" fileType="personeelsreglement" />
					</div>
					{/* <div className="pb-12">
						<Heading size="2">Overeenkomsten van opdracht</Heading>
						<FileList limit="3" fileType="ovo" />
						
					</div> */}
					<Heading size="2">Arbeidsovereenkomsten</Heading>
					<FileList limit="3" fileType="contract" />
					
				</div>
			</div>
		</Container>
	);
};

export default Dashboard;
