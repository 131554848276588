import React, { useState } from "react";

import Modal from "react-modal";
import mixpanel from "mixpanel-browser";
import { useUser } from "@clerk/clerk-react";

import useFetch from "../../shared/hooks/useFetch";
import { EyeIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const ViewFileButton = ({ fileID, fileType, className, bg = "light" }) => {
	return null;

	// const fetchDocument = useFetch();
	// const { user } = useUser();

	// const [isOpen, setIsOpen] = useState(false);
	// const [fileURL, setFileURL] = useState(null);

	// const viewFile = async () => {
	// 	mixpanel.track(`👁 Preview van ${fileType} getoond`, {
	// 		distinct_id: user.id,
	// 		fileID: fileID,
	// 	});

	// 	const apiEndpoint =
	// 		fileType === "personeelsreglement"
	// 			? "/api/reglementen"
	// 			: "/api/contracten";
	// 	const uri = `${apiEndpoint}/download/${fileID}/?returnType=url&fileType=pdf`;

	// 	try {
	// 		const response = await fetchDocument(uri);
	// 		setFileURL(response.url);
	// 	} catch (error) {
	// 		console.error("There was an issue downloading the file:", error);
	// 	}
	// 	setIsOpen(true);
	// };

	// return (
	// 	<>
	// 		<button
	// 			onClick={viewFile}
	// 			className={`flex font-medium hover:scale-105 ${className}`}
	// 		>
	// 			<EyeIcon
	// 				className={`h-6 w-6 mr-2 ${
	// 					bg === "light" ? "text-blue-600" : "text-white"
	// 				}`}
	// 			/>
	// 			Bekijken
	// 		</button>
	// 		<Modal
	// 			isOpen={isOpen}
	// 			onRequestClose={() => setIsOpen(false)}
	// 			contentLabel="PDF Viewer"
	// 			style={{
	// 				overlay: {
	// 					backgroundColor: "rgba(0, 0, 0, 0.45)",
	// 				},
	// 				content: {
	// 					borderRadius: "14px",
	// 					padding: "0",
	// 					border: "none",
	// 					top: "60px",
	// 					bottom: "60px",
	// 					left: "80px",
	// 					right: "80px",
	// 					backgroundColor: "#525659",
	// 				},
	// 			}}
	// 		>
	// 			<div className="absolute z-40 bottom-4 left-1/2 transform -translate-x-1/2">
	// 				<p className="text-center text-white bg-gray-700 py-3 px-6 rounded-full shadow-md opacity-90">
	// 					Dit is een preview van het {fileType}. Download het
	// 					Word-bestand voor de juiste formatting en om
	// 					aanpassingen te doen
	// 				</p>
	// 			</div>
	// 			{!fileURL && <LoadingSpinner />}
	// 			{fileURL && (
	// 				<embed
	// 					src={`${fileURL}#toolbar=0&navpanes=0&scrollbar=1`}
	// 					type="application/pdf"
	// 					width="100%"
	// 					height="100%"
	// 				/>
	// 			)}
	// 		</Modal>
	// 	</>
	// );
};

export default ViewFileButton;
