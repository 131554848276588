import React from "react";

import { SignUp } from "@clerk/clerk-react";

const SignUpForm = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const email = queryParams.get("email");
	const firstName = queryParams.get("firstName");
	const lastName = queryParams.get("lastName");
	const praktijkNaam = queryParams.get("praktijkNaam");
	const praktijkAdres = queryParams.get("praktijkAdres");
	const praktijkPlaats = queryParams.get("praktijkPlaats");
	const praktijkPostcode = queryParams.get("praktijkPostcode");

	return (
		<div className="mt-signup">
			<div className="mb-3">
				<label
					htmlFor={"mt_input_email"}
					className="block text-sm font-medium leading-6
					text-gray-900"
				>
					E-mailadres
				</label>

				<div className="mt-2 relative rounded-md shadow-sm">
					<input
						disabled
						value={email}
						className={`cursor-not-allowed block w-full border-0 py-1.5 text-gray-500 rounded-md ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6`}
					/>
				</div>
			</div>
			<div className="pb-8 border-b">
				<label
					htmlFor={"mt_input_email"}
					className="block text-sm font-medium leading-6
					text-gray-900"
				>
					Praktijk
				</label>

				<div className="mt-2 relative rounded-md shadow-sm">
					<input
						disabled
						value={praktijkNaam}
						className={`cursor-not-allowed block w-full border-0 py-1.5 text-gray-500 rounded-md ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6`}
					/>
				</div>
			</div>

			<SignUp
				appearance={{
					elements: {
						header: "hidden",
						card: "w-full p-0 m-0 bg-transparent shadow-none",
						rootBox: "w-full",
						formFieldInput:
							"rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6",
						formButtonPrimary:
							"bg-blue-600 hover:bg-blue-700 hover:scale-105 transition ease-in-out duration-300 rounded-lg normal-case text-base shadow-sm font-semibold tracking-normal py-3 text-white",
						footerActionLink: "text-blue-600 hover:text-blue-700",
						footer: "hidden",
						formField__emailAddress: "hidden",
						formField__firstName: "hidden",
						formField__lastName: "hidden",
					},
				}}
				localization={{
					formFieldErrorText_emailAddress:
						"Dat e-mailadres is al in gebruik. Probeer een ander.",
				}}
				redirectUrl="/?signedUp=true"
				initialValues={{
					emailAddress: email,
					firstName: firstName,
					lastName: lastName,
				}}
				unsafeMetadata={{
					praktijkNaam: praktijkNaam,
					initialPraktijkNaam: praktijkNaam,
					praktijkAdres: praktijkAdres,
					praktijkPostcode: praktijkPostcode,
					praktijkPlaats: praktijkPlaats,
				}}
			/>
		</div>
	);
};

export default SignUpForm;
