import React, { useState, useEffect } from "react";

import { ArrowDownIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../shared/hooks/useFetch";

import Heading from "../../shared/components/UIElements/Heading";
import Button from "../../shared/components/FormElements/Button";
import Container from "../../shared/components/UIElements/Container";

import InviteTeamButton from "../components/InviteTeamButton";
import NPS from "../components/NPS";
import Scores from "../components/Scores";
import OpenVragen from "../components/OpenVragen";
import DataTile from "../components/DataTile";
import ReportAI from "../components/ReportAI";

import gradientBg from "../../img/gradientBg.jpeg";

const Teamtevredenheid = () => {
	const { user } = useUser();
	const fetchAnalytics = useFetch();

	const [analytics, setAnalytics] = useState({
		nps: {},
		scores: {},
		totalResponses: 0,
	});
	const [openQuestions, setOpenQuestions] = useState({});
	const [aiReport, setAiReport] = useState();
	const [openSummary, setOpenSummary] = useState();

	const praktijkNaam = user.unsafeMetadata.initialPraktijkNaam;

	useEffect(() => {
		if (praktijkNaam) {
			const getAnalytics = async () => {
				const openResponse = await fetchAnalytics(
					`/api/tevredenheid/openQuestions/${user.id}`
				);
				setOpenQuestions(openResponse.openQuestions);

				const analyticsResponse = await fetchAnalytics(
					`/api/tevredenheid/analytics/${user.id}`
				);
				setAnalytics(analyticsResponse);

				const reportResponse = await fetchAnalytics(
					`/api/tevredenheid/report/${user.id}`
				);
				if (reportResponse.message) {
					setAiReport(null);
				} else {
					setAiReport(reportResponse);
				}

				const openSummary = await fetchAnalytics(
					`/api/tevredenheid/openQuestions/summary/${user.id}`
				);

				if (openSummary.message) {
					setOpenSummary(null);
				} else {
					setOpenSummary(openSummary);
				}
			};

			getAnalytics();
		}
	}, [fetchAnalytics, user.id, praktijkNaam]);

	if (!praktijkNaam) {
		return (
			<Container>
				<h3 className="font-semibold text-xl text-center">
					Het lijkt erop dat er geen praktijknaam is gekoppeld aan je
					account...
				</h3>
				<p className="text-gray-700 text-center text-lg">
					Neem contact met ons op via de chat, dan lossen we het voor
					je op.
				</p>
			</Container>
		);
	}

	return (
		<div className="bg-[#F8FAFC] teamtevredenheid overflow-x-hidden">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 lg:pr-20 pt-20 pb-2">
				<div className="pb-10 mb-12 text-center">
					<Heading>
						Krijg inzicht in de tevredenheid van je team
					</Heading>
					<p className="text-slate-500 pt-5 text-base">
						Met dit onderzoek kunnen medewerkers dertien eenvoudige
						stellingen beantwoorden. De score vertelt jou als
						praktijkhouder of praktijkmanager hoe het gaat met jouw
						team. De twee open vragen geven gelegenheid voor meer
						specifieke input.
					</p>
					<div className="flex center pt-4">
						<InviteTeamButton />
						{analytics.totalResponses !== 0 && (
							<Button
								href="#resultaten"
								classNames="flex font-medium bg-white text-blue-600 hover:bg-white border-2 border-blue-600 hover:border-blue-600 hover:scale-105 w-fit"
							>
								<ArrowDownIcon className="h-6 w-6 mr-2 text-blue-600" />
								<span className="text-blue-600">
									Bekijk resultaten
								</span>
							</Button>
						)}
					</div>
				</div>
			</div>
			<div className="bg-[#DFE6F3] text-[#38567a] py-4 flex center sm:px-0 px-8">
				<ArrowPathIcon className="sm:h-6 sm:w-6 w-10 h-10 mr-3 text-[#38567a]" />
				<p>
					Dit is een dynamisch rapport; zodra teamleden de vragenlijst
					invullen, worden onderstaande resultaten geüpdate
				</p>
			</div>
			{analytics.totalResponses !== 0 && (
				<>
					<div className="bg-white border-b">
						<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 lg:pr-20 py-10">
							<div className="flex flex-col sm:flex-row pl-4 sm:pl-0">
								<div className="w-full sm:w-1/2">
									<DataTile
										data={analytics.totalResponses}
										label="Aantal respondenten"
										classNames={
											"sm:float-right sm:pr-16 pb-6 sm:pb-0"
										}
									/>
								</div>
								<DataTile
									label={"Opdrachtgever"}
									data={`${user.firstName} ${user.lastName}`}
									classNames="w-full sm:w-1/2 sm:pl-8"
								/>
							</div>
						</div>
					</div>
					<div>
						{aiReport && (
							<div
								className="px-4 sm:px-24 py-10 sm:py-20"
								id="ai"
								style={{
									backgroundImage: `url(${gradientBg})`,
									backgroundSize: "cover",
									backgroundPosition: "center",
									repeat: "no-repeat",
								}}
							>
								<div className="bg-white shadow-lg rounded-2xl px-6 pb-4 pt-6 sm:px-20 sm:py-20">
									<ReportAI
										report={aiReport.improvementReport}
										createdAt={aiReport.createdAt}
										setReport={setAiReport}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="bg-[#00103C] pt-20 pb-24" id="resultaten">
						<h2 className="font-bold text-3xl text-white text-center">
							Bekijk je resultaten
						</h2>
						<p className="text-slate-300 text-center text-md pt-3">
							Hieronder vind je de analyse van het
							medewerkersonderzoek dat is uitgezet bij jou in de
							praktijk
						</p>
					</div>
					<div className="flex pt-8 pb-16 sm:max-w-7xl max-w-full mx-auto px-4 sm:px-6 lg:px-16 lg:pr-20 sm:space-x-10 ">
						<div className="sm:w-1/4 hidden sm:inline">
							<div className="bg-[#e8ecf1] py-8 px-10 rounded-2xl sticky top-10">
								<h4 className="font-bold text-lg pb-2">
									Resultaten
								</h4>
								<ul>
									<li className="mb-1.5">
										<a
											href="#nps"
											className="text-blue-700"
										>
											NPS score
										</a>
									</li>

									<li className="mb-1.5">
										<a
											href="#scores"
											className="text-blue-700"
										>
											Scores
										</a>
									</li>

									<li className="pb-2">
										<a
											href="#open"
											className="text-blue-700"
										>
											Open vragen
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="sm:w-3/4">
							<div
								className="bg-white shadow-lg rounded-2xl px-8 sm:px-14 py-12 -mt-16"
								id="nps"
							>
								<NPS score={analytics.nps.score} />
							</div>

							<div
								className="bg-white shadow-lg rounded-2xl px-8 sm:px-14 py-12 mt-8"
								id="scores"
							>
								<Scores scores={analytics.scores} />
							</div>
							<div
								className="bg-white shadow-lg rounded-2xl px-8 sm:px-14 py-12 mt-8"
								id="open"
							>
								<OpenVragen
									answers={openQuestions}
									aiSummaries={openSummary}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Teamtevredenheid;
