import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";

import useFetch from "../../shared/hooks/useFetch";

import FileItem from "./FileItem";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Button from "../../shared/components/FormElements/Button";
import { PlusIcon } from "@heroicons/react/20/solid";

const FileList = (props) => {
	const fetch = useFetch();
	const { user } = useUser();

	const [contracts, setContracts] = useState([]);
	const [showAll, setShowAll] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const estimatedItemHeight = 95;

	const apiEndpoint = {
		contract: "/api/contracten",
		ovo: "/api/ovo",
		personeelsreglement: "/api/reglementen",
	};

	useEffect(() => {
		const fetchFiles = async () => {
			const data = await fetch(
				`${apiEndpoint[props.fileType]}/user/${user.id}?status=${
					props.status || "final"
				}`
			);
			setIsLoading(false);
			setContracts(data);
		};

		fetchFiles();
	}, [fetch]);

	const displayContracts = showAll
		? contracts
		: contracts.slice(0, props.limit);

	if (isLoading) {
		return <LoadingSpinner />;
	}

	if (contracts.length === 0) {
		return (
			<div>
				<p className="text-gray-500 pt-4">
					Je hebt nog geen {props.fileType} aangemaakt.
				</p>
				<Button
					to={`/nieuw/${props.fileType}`}
					classNames="flex items-center -mt-1 w-fit"
				>
					<PlusIcon className="w-5 h-5 mr-2 -ml-2" />
					Nieuw {props.fileType}
				</Button>
			</div>
		);
	}

	return (
		<div>
			{contracts.slice(0, props.limit).map((contract, index) => (
				<div
					style={{
						borderBottom:
							index !== displayContracts.length - 1
								? "1px solid #E2E8F0"
								: "none",
					}}
				>
					<FileItem
						key={contract.id}
						file={contract}
						fileType={
							contract.type === "ovo" ? "ovo" : props.fileType
						}
						status={props.status}
					/>
				</div>
			))}
			<div
				className={`collapsible-list ${!showAll ? "collapsed" : ""}`}
				style={{
					maxHeight: showAll
						? `${
								(contracts.length - props.limit) *
								estimatedItemHeight
						  }px`
						: "0",
				}}
			>
				{contracts.slice(props.limit).map((contract, index) => (
					<div
						style={{
							borderBottom:
								index !== contracts.length - props.limit - 1
									? "1px solid #E2E8F0"
									: "none",
						}}
					>
						<FileItem
							key={contract.id}
							file={contract}
							fileType={props.fileType}
							status={props.status}
						/>
					</div>
				))}
			</div>
			<div>
				{contracts.length > props.limit && !showAll && (
					<button
						onClick={() => setShowAll(true)}
						className="text-blue-600 font-semibold hover:text-blue-700 transition-colors duration-100"
					>
						{props.fileType === "personeelsreglement"
							? contracts.length - props.limit === 1
								? "+ 1 ouder personeelsreglement"
								: `+ ${
										contracts.length - props.limit
								  } oudere personeelsreglementen`
							: contracts.length - props.limit === 1
							? "+ 1 ander contract"
							: `+ ${
									contracts.length - props.limit
							  } andere contracten`}
					</button>
				)}
				{showAll && (
					<button
						onClick={() => setShowAll(false)}
						className="text-blue-600 font-semibold hover:text-blue-700 transition-colors duration-100"
					>
						{props.fileType === "personeelsreglement"
							? `- ${
									contracts.length - props.limit
							  } personeelsreglementen verbergen`
							: `- ${
									contracts.length - props.limit
							  } contracten verbergen`}
					</button>
				)}
			</div>
		</div>
	);
};

export default FileList;
