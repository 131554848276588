import React, { useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";

import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/20/solid";
import mixpanel from "mixpanel-browser";

const ReportFeedback = ({ report }) => {
	const { user } = useUser();
	const praktijkNaam = user.unsafeMetadata.initialPraktijkNaam;

	const [positiveClicked, setPositiveClicked] = useState(false);
	const [negativeClicked, setNegativeClicked] = useState(false);

	const setPositive = () => {
		mixpanel.track("👍 AI Verbeterplan: Feedback gegeven", {
			distinct_id: user.id,
			naam: `${user.firstName} ${user.lastName}`,
			praktijkNaam: praktijkNaam,
			report: report,
		});

		toast.success("Feedback gegeven", {
			position: "bottom-center",
			theme: "light",
			hideProgressBar: true,
			duration: 800,
			icon: (
				<CheckCircleIcon
					className={`text-green-500`}
					aria-hidden="true"
				/>
			),
		});
		setPositiveClicked(true);
		setNegativeClicked(false);
	};

	const setNegative = () => {
		mixpanel.track("👎 AI Verbeterplan: Feedback gegeven", {
			distinct_id: user.id,
			naam: `${user.firstName} ${user.lastName}`,
			praktijkNaam: praktijkNaam,
			report: report,
		});
		toast.success("Feedback gegeven", {
			position: "bottom-center",
			theme: "light",
			hideProgressBar: true,
			duration: 800,
			icon: (
				<CheckCircleIcon
					className={`text-green-500 ${
						negativeClicked ? "bg-green-200" : ""
					}`}
					aria-hidden="true"
				/>
			),
		});
		setPositiveClicked(false);
		setNegativeClicked(true);
	};

	return (
		<>
			<button onClick={setPositive} disabled={positiveClicked}>
				<HandThumbUpIcon
					className={` hover:text-green-500 hover:scale-110 ml-1 h-5 w-5 inline mr-1 ${
						positiveClicked ? "text-green-500" : "text-slate-400"
					}`}
				/>{" "}
			</button>
			<button onClick={setNegative} disabled={negativeClicked}>
				<HandThumbDownIcon
					className={` hover:text-orange-500 hover:scale-110 h-5 w-5 inline mr-1 ${
						negativeClicked ? "text-orange-500" : "text-slate-400"
					}`}
				/>{" "}
			</button>
		</>
	);
};

export default ReportFeedback;
