import React from "react";

import GaugeChart from "./GaugeChart";

const DataMeter = ({
	question,
	minValue = 0,
	maxValue = 5,
	currentValue,
	name,
}) => {
	return (
		<div className="text-center dataMeter">
			<GaugeChart
				minValue={minValue}
				maxValue={maxValue}
				currentValue={currentValue}
				name={name}
			/>
			<p className="mt-5 pb-2">{question}</p>
		</div>
	);
};

export default DataMeter;
