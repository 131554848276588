import React, { useState } from "react";
import Modal from "react-modal";

import Lightbulb from "../../img/Lightbulb.png";
import { ArrowUpRightIcon, XMarkIcon } from "@heroicons/react/20/solid";

const FormTip = ({ title, description, extraInfo }) => {
	const [isOpen, setIsOpen] = useState(false);

	function openModal() {
		setIsOpen(true);
	}

	return (
		<>
			<div className="border-l-2 border-slate-200 w-full lg:w-1/2 ml-12 pl-12 flex">
				<div className="pt-5 sticky top-10">
					<img src={Lightbulb} className="w-16 mb-6 -ml-3" alt="" />
					<h4 className="font-semibold text-lg mb-6">{title}</h4>
					<p
						className="text-gray-500"
						dangerouslySetInnerHTML={{
							__html: description,
						}}
					></p>
					{extraInfo && (
						<button
							className="text-blue-500 font-medium mt-6 flex"
							onClick={openModal}
						>
							Lees verder
							<ArrowUpRightIcon className="w-4 h-4 mt-1 ml-1.5" />
						</button>
					)}
				</div>
			</div>
			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				contentLabel="PDF Viewer"
				style={{
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.45)",
					},
					content: {
						borderRadius: "18px",
						border: "none",
						top: "17%",
						bottom: "17%",
						left: "25%",
						right: "25%",
					},
				}}
			>
				<div className="text-right pt-4 pr-4 sticky top-1">
					<button onClick={() => setIsOpen(false)}>
						<XMarkIcon className="w-7 h-7 text-gray-400 hover:text-gray-500 transition-colors" />
					</button>
				</div>
				<div className="px-10 pt-3 pb-6 mt-tipmodal">
					<p dangerouslySetInnerHTML={{ __html: extraInfo }}></p>
				</div>
			</Modal>
		</>
	);
};

export default FormTip;
