import React from "react";
import DataMeter from "./DataMeter";

const Scores = ({ scores = {} }) => {
	const categories = {
		"Werkomgeving en hulpmiddelen": [
			"praktijkVerwacht",
			"beschikMaterialen",
		],
		"Persoonlijke ontwikkeling en vooruitgang": [
			"stimuleertOntwikkeling",
			"vooruitgangGesprek",
			"lerenGroeien",
		],
		"Erkenning en waardering": ["wekelijkseErkenning", "meningTelt"],
		"Persoonlijke Betekenis en Betrokkenheid": [
			"elkeDagGelegenheid",
			"missieWerkBelangrijk",
		],
		"Relaties en teamdynamiek": [
			"persoonlijkeZorg",
			"collegasKwaliteit",
			"klikCollega",
		],
	};

	return (
		<>
			<h3 className="text-2xl font-bold pb-2">Scores per stelling​</h3>
			<p className="text-gray-600 pb-5">
				Hieronder zie je per stelling jouw score (op een schaal van
				1-5). Je kunt niet overal even goed in zijn...
			</p>

			{/* Loop door elke categorie */}
			{Object.keys(categories).map((category) => (
				<div key={category}>
					<h4 className="text-xl font-bold border-t border-gray-200 pb-8 pt-8 mt-6">
						{category}
					</h4>
					<div className="grid sm:grid-cols-3 grid-cols-2 gap-4">
						{categories[category].map((key) => (
							<div key={key}>
								<DataMeter
									currentValue={scores[key].average}
									question={scores[key].question}
									name={key}
								/>
							</div>
						))}
					</div>
				</div>
			))}
		</>
	);
};

export default Scores;
