import React from "react";
import { useClerk } from "@clerk/clerk-react";
import Button from "../../shared/components/FormElements/Button";

const SignOutButton = () => {
	const { signOut } = useClerk();
	return <Button onClick={() => signOut()}>Uitloggen</Button>;
};

export default SignOutButton;
