import React from "react";
import NPSMeter from "./NPSMeter";

const NPS = ({ score }) => {
	return (
		<>
			<h3 className="text-2xl font-bold pb-2">NPS Score</h3>
			<p className="text-gray-600 pb-10">
				Hoe werkt de NPS? Op een schaal van 0 tot 10 meet de NPS de mate
				waarin werknemers hun werkgever zouden aanraden bij anderen.{" "}
				<br />
				<br />
				Werknemers worden vervolgens aan de hand van hun beoordeling
				ingedeeld in drie categorieën:  <br />
				<ul className="list-disc list-inside pl-2 pt-4">
					<li>
						<strong>Promotors</strong>, score 9 – 10: dit zijn de
						ambassadeurs van de organisatie.{" "}
					</li>
					<li>
						<strong>Passieven</strong>, score 7 - 8 : dit zijn de
						mensen die tevreden zijn.
					</li>
					<li>
						<strong>Criticasters</strong>, score 0 - 6:  dit zijn de
						mensen die (zeer) ontevreden zijn.
					</li>
				</ul>{" "}
				<br />
				Je berekent het verschil tussen het percentage Promotors en
				Criticasters. De NPS zelf wordt niet uitgedrukt als een
				percentage, maar als een absoluut getal tussen -100 en 100. {" "}
				<br />
				<br />
				Bijvoorbeeld, als je 25% Promotors, 55% Passief Tevredenen en
				20% Criticasters hebt, dan is de NPS +5 (25-20). <br />
				<br />
				Een NPS score ligt tussen de -100 en +100. Gemiddeld ligt de NPS
				in Nederland in 2021 op +55.
			</p>
			<NPSMeter score={score} />
		</>
	);
};

export default NPS;
