import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = ({ fileType }) => {
	const navigate = useNavigate();

	const goBack = () => {
		if (!fileType) {
			navigateToDefault();
		} else {
			navigate(`/${fileType}en/`);
		}
	};

	const navigateToDefault = () => {
		navigate("/");
	};

	return (
		<button
			onClick={goBack}
			className="font-medium text-gray-500 text-md hover:text-gray-700"
		>
			← Terug
		</button>
	);
};

export default BackButton;
