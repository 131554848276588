import React from "react";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import Button from "../../shared/components/FormElements/Button";

const DuplicateFileButton = ({ fileID, fileType }) => {
	return (
		<Button
			to={`/nieuw/${fileType}/?copy=${fileID}`}
			classNames="flex font-medium bg-white text-blue-600 hover:bg-white border-2 border-blue-600 hover:border-blue-600 hover:scale-105 w-fit"
		>
			<DocumentDuplicateIcon className="h-6 w-6 mr-2 text-blue-600" />
			<span className="text-blue-600">Dupliceer document</span>
		</Button>
	);
};

export default DuplicateFileButton;
