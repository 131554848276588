export const shouldDisplayField = (condition, values) => {
	if (!condition) return true;

	const evaluateRule = (rule) => {
		const fieldValue = values[rule.field];

		switch (rule.operation) {
			case "EQUALS":
				return fieldValue === rule.value;
			case "CONTAINS":
				return fieldValue?.includes(rule.value);
			case "GREATER_THAN":
				return parseFloat(fieldValue) > parseFloat(rule.value);
			case "LESS_THAN":
				return parseFloat(fieldValue) < parseFloat(rule.value);
			case "NOT_EQUALS":
				return fieldValue !== rule.value;
			default:
				return true;
		}
	};

	// Handle combining rules based on operator
	switch (condition.operator) {
		case "AND":
			return condition.rules.every(evaluateRule);
		case "OR":
			return condition.rules.some(evaluateRule);
		default:
			return true;
	}
};
