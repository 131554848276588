import React from "react";

import SignUpForm from "../components/SignUpForm";

import AuthFooter from "../components/AuthFooter";
import LogoMark from "../../shared/components/Branding/LogoMark";
import Heading from "../../shared/components/UIElements/Heading";

const SignUpPage = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const email = queryParams.get("email");
	const firstName = queryParams.get("firstName");
	const lastName = queryParams.get("lastName");

	if (!email || !firstName || !lastName) {
		return (
			<div className="bg-white h-screen grid sm:grid-cols-2">
				<div className="grid content-between px-12 sm:px-24">
					<LogoMark className="mt-12 mb-12 sm:mb-0" />

					<div>
						<div className="pb-12">
							<Heading className="leading-9">
								Stel je wachtwoord in voor DentHR
							</Heading>
							<p className="pt-8 text-red-700">
								Sorry, maar het lijkt erop dat je geen account
								hoort aan te kunnen maken via deze link.
								<br />
								<br />
								Klopt dit niet? Neem contact met ons op via de
								chat rechtsonder.
							</p>
						</div>

						<p className="pt-6 center text-sm text-gray-800">
							Heb je je wachtwoord al eerder ingesteld?{"  "}
							<a
								href="/inloggen"
								className="text-blue-500 font-semibold hover:text-blue-600 ml-1"
							>
								Log hier in
							</a>
						</p>
					</div>

					<div className="mb-12 sm:flex justify-between">
						<AuthFooter />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="bg-white h-screen grid sm:grid-cols-2">
			<div className="grid content-between px-12 sm:px-24">
				<LogoMark className="mt-12 mb-12 sm:mb-0" />

				<div>
					<div className="pb-12">
						<Heading className="leading-9">
							Stel je wachtwoord in voor DentHR
						</Heading>
						<p className="pt-4 text-gray-700">
							Je aanmelding is goed gegaan, nu alleen je
							wachtwoord nog!
						</p>
					</div>

					<SignUpForm />
					<p className="pt-6 center text-sm text-gray-800">
						Heb je je wachtwoord al eerder ingesteld?{"  "}
						<a
							href="/inloggen"
							className="text-blue-500 font-semibold hover:text-blue-600 ml-1"
						>
							Log hier in
						</a>
					</p>
				</div>

				<div className="mb-12 sm:flex justify-between">
					<AuthFooter />
				</div>
			</div>
		</div>
	);
};

export default SignUpPage;
