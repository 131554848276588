import React from "react";

const Container = ({ className, transparent, paddingTop = true, ...props }) => {
	return (
		<div
			className={`${className} ${transparent ? "" : "bg-[#F8FAFC]"} ${
				paddingTop ? "pt-20" : ""
			} pb-20 min-h-screen`}
			{...props}
		>
			<div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 lg:pr-20`}>
				{props.children}
			</div>
		</div>
	);
};

export default Container;
