import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

const ContinueDraft = ({ fileID, fileType }) => {
	return (
		<Link
			to={`/nieuw/${fileType}/?draft=${fileID}`}
			className="flex font-medium hover:scale-105"
		>
			Ga verder
			<ArrowRightIcon className="h-6 w-6 ml-2 text-blue-600" />
		</Link>
	);
};

export default ContinueDraft;
