import { ArrowTopRightOnSquareIcon, PlusIcon } from "@heroicons/react/24/solid";
import mixpanel from "mixpanel-browser";
import React from "react";
import { useLocation, Link } from "react-router-dom";

const NavItem = ({ item }) => {
	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}

	item.current = item.href === useLocation().pathname;

	return (
		<div className={item.classNames}>
			{item.external ? (
				// For external links, use the <a> tag
				<a
					key={item.name}
					href={item.href}
					onClick={(e) => {
						console.log(`🔗 Geopend: ${item.name}`);
						mixpanel.track(`🔗 Geopend: ${item.name}`, {
							link: item.href,
						});
					}}
					target="_blank"
					rel="noopener noreferrer"
					className={classNames(
						item.current
							? "text-black"
							: "text-gray-400 hover:bg-gray-100 hover:text-gray-900 duration-100 transition-colors",
						"group flex items-center px-2 py-2 text-md rounded-lg font-semibold ph-autocapture"
					)}
				>
					<item.icon
						className={classNames(
							item.current
								? "text-blue-600"
								: "text-gray-400 group-hover:text-gray-500",
							"mr-3 flex-shrink-0 h-6 w-6"
						)}
						aria-hidden="true"
					/>
					{item.name}
					{item.external && (
						<ArrowTopRightOnSquareIcon className="w-4 ml-2 opacity-60" />
					)}
				</a>
			) : (
				// For internal links, use the <Link> component
				<Link
					to={item.href}
					className={classNames(
						item.current
							? "text-black"
							: "text-gray-400 hover:bg-gray-100 hover:text-gray-900 duration-100 transition-colors",
						"group flex items-center px-2 py-2 text-md rounded-lg font-semibold ph-autocapture"
					)}
				>
					<item.icon
						className={classNames(
							item.current
								? "text-blue-600"
								: "text-gray-400 group-hover:text-gray-500",
							"mr-3 flex-shrink-0 h-6 w-6"
						)}
						aria-hidden="true"
					/>
					{item.name}
					{item.plusIcon && (
						<Link to="/nieuw/contract" className="ml-auto">
							<PlusIcon
								className="ml-auto h-5 w-5 text-gray-400 group-hover:text-gray-500 ph-autocapture"
								aria-hidden="true"
							/>
						</Link>
					)}
				</Link>
			)}
		</div>
	);
};

export default NavItem;
