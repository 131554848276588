import React from "react";
import AISummary from "./AISummary";

const OpenVragen = ({ answers, aiSummaries }) => {
	if (answers)
		return (
			<>
				<h3 className="text-2xl font-bold pb-2">
					Antwoorden op de open vragen​
				</h3>
				<p className="text-gray-600">
					Hieronder vind je de antwoorden op de twee open vragen: 'Wat
					zou jij het liefst veranderd zien in de komende twee jaar?'
					en 'Wat kost het meeste tijd of energie in de praktijk?'
				</p>

				{answers &&
					Object.keys(answers).map((key) => (
						<>
							<h4 className="font-bold text-lg pb-6 pt-14">
								{answers[key].question}
							</h4>
							{aiSummaries && (
								<AISummary text={aiSummaries[key]} />
							)}
							<div className="grid grid-cols-2 gap-4">
								{answers[key].answers &&
									answers[key].answers.map(
										(answer, index) => (
											<div
												className="bg-white border border-gray-200 p-4 rounded-xl text-gray-800  italic"
												key={index}
											>
												"{answer}"
											</div>
										)
									)}
							</div>
						</>
					))}
			</>
		);
};

export default OpenVragen;
