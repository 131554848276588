import React from "react";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

import useFetch from "../../shared/hooks/useFetch";

const DeleteButton = ({ fileID, fileType, classNames, iconOnly }) => {
	const updateDoc = useFetch();
	const navigate = useNavigate();

	const endpoint = {
		contract: "api/contracten",
		personeelsreglement: "api/reglementen",
		ovo: "api/ovo",
	};

	const deleteFile = async () => {
		if (
			window.confirm("Weet je zeker dat je dit bestand wilt verwijderen?")
		) {
			const uri = `/${endpoint[fileType]}/${fileID}/`;

			try {
				await updateDoc(uri, {
					method: "DELETE",
				});

				toast(`Het ${fileType} is verwijderd`, {
					position: "bottom-center",
					theme: "light",
					icon: null,
					hideProgressBar: true,
					autoClose: 2000,
				});

				if (window.location.pathname === `/${fileType}en/`) {
					window.location.reload();
				}

				navigate(`/${fileType}en/`);
			} catch (error) {
				console.error("There was an issue deleting the file:", error);
				toast(`Er is iets misgegaan: ${error}`, {
					position: "bottom-center",
					theme: "light",
					icon: null,
					hideProgressBar: false,
					autoClose: 2000,
				});
			}
		}
	};

	if (iconOnly) {
		return (
			<button
				onClick={deleteFile}
				className={`flex font-medium ${classNames}`}
			>
				<TrashIcon
					className={`h-5 w-5 mr-2 text-gray-500 hover:text-red-500 transition-all duration-100 hover:scale-105`}
				/>
			</button>
		);
	}

	return (
		<button
			onClick={deleteFile}
			className={`flex font-medium hover:scale-105 ${classNames}`}
		>
			<TrashIcon className={`h-6 w-6 mr-2 text-red-600`} />
			Verwijderen
		</button>
	);
};

export default DeleteButton;
