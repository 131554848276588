import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { ClerkProvider } from "@clerk/clerk-react";
import { nlNL } from "@clerk/localizations";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";
import Modal from "react-modal";

import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	// <React.StrictMode>
	<IntercomProvider appId="uxltrzl0" autoBoot>
		<ClerkProvider publishableKey={clerkPubKey} localization={nlNL}>
			<App />
			<ToastContainer />
		</ClerkProvider>
	</IntercomProvider>
	// </React.StrictMode>
);

Modal.setAppElement("#root");
