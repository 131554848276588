import React from "react";

const FormattedNumber = ({ data, locale = "nl-NL", decimals }) => {
	// Check if the data is a number before formatting
	let content;
	if (typeof data === "number") {
		// If decimals is set, round the number to that number of decimals
		// If decimals is not set, default to 0
		data = data.toFixed(decimals !== undefined ? decimals : 0);
		content = new Intl.NumberFormat(locale, {
			minimumFractionDigits: decimals !== undefined ? decimals : 0,
		}).format(data);
	} else {
		content = data;
	}

	return <span>{content}</span>;
};
export default FormattedNumber;
