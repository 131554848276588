import React, { useState } from "react";
import { useField, useFormikContext } from "formik";

const RadioGroup = ({
	label,
	description,
	options = [
		{ label: "Ja", value: "ja" },
		{ label: "Nee", value: "nee" },
	],
	includeOther = false,
	...props
}) => {
	const [field, meta] = useField(props);
	const [isOtherSelected, setIsOtherSelected] = useState(false);

	let optionsArray = options;

	if (optionsArray.length === 0) {
		optionsArray = [
			{ label: "Ja", value: "ja" },
			{ label: "Nee", value: "nee" },
		];
	}

	const { setFieldValue } = useFormikContext();

	// Handler to manage the other input value
	const handleOtherInputChange = (e) => {
		setFieldValue(field.name, e.target.value);
	};

	return (
		<div className={props.width ? "w-" + props.width : "w-full"}>
			<div className={`${props.className} mt-8 px-2 mb-2`}>
				<label className="block text-sm font-medium leading-6 text-gray-900">
					{label}
				</label>
				<p
					className="text-sm leading-5 text-gray-500"
					dangerouslySetInnerHTML={{ __html: description }}
				></p>
				<fieldset className="mt-4">
					<div className="space-y-2">
						{optionsArray.map((option, index) => (
							<div key={index} className="flex items-center">
								<input
									id={field.name + "_" + option.value}
									name={field.name}
									type="radio"
									{...field}
									value={option.value}
									checked={field.value === option.value}
									className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 cursor-pointer"
								/>
								<label
									htmlFor={field.name + "_" + option.value}
									className="ml-3 block text-sm text-gray-700 cursor-pointer"
								>
									{option.label}
								</label>
							</div>
						))}
						{includeOther && (
							<div className="flex items-center">
								<input
									id="otherOption"
									name={field.name}
									type="radio"
									{...field}
									value="other"
									checked={isOtherSelected}
									onChange={() => {
										setIsOtherSelected(true);
										setFieldValue(field.name, "other");
									}}
									className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
								/>
								<label
									htmlFor="otherOption"
									className="ml-3 block text-sm font-medium text-gray-700"
								>
									Anders, namelijk...
								</label>
								{isOtherSelected && (
									<input
										type="text"
										onChange={handleOtherInputChange}
										placeholder="Vul hier in..."
										className="ml-3 py-2 px-4 rounded border border-gray-300"
									/>
								)}
							</div>
						)}
					</div>
					{meta.touched && meta.error ? (
						<div className="error mt-2">{meta.error}</div>
					) : null}
				</fieldset>
			</div>
		</div>
	);
};

export default RadioGroup;
