import React from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import mixpanel from "mixpanel-browser"; // import mixpanel library

import Container from "../components/UIElements/Container";
import Heading from "../components/UIElements/Heading";
import Button from "../components/FormElements/Button";

import excelPhoto from "../../img/Excel.png";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

const Downloads = () => {
	const trackDownload = (fileName, downloadLink) => {
		mixpanel.track(`💾 Download: ${fileName}`, { downloadLink });
	};

	return (
		<Container>
			<div className="pb-10 mb-12 border-b border-gray-200">
				<Heading>Downloads</Heading>
				<p className="text-gray-600 pt-2">
					Als abbonnee van DentHR krijg je toegang tot een aantal
					handige downloads die je helpen bij het opstellen van je
					contracten.
				</p>
			</div>
			<div className="mt-8">
				<div className="px-12 py-12 bg-white shadow rounded-3xl flex justify-between">
					<div>
						<h3 className="text-lg leading-6 font-semibold text-gray-900">
							Salarisschalen
						</h3>
						<p className="pt-3 text-gray-600 mb-2">
							Dit salarishuis kun je eventueel zelf nog aanpassen.
						</p>
						<Button
							href="https://www.dropbox.com/scl/fo/4l3yr6hj2jk75sr5d21ev/h?rlkey=6pl65bzs45wwv66koydkjr3i5&dl=1"
							classNames="flex w-fit pr-6 ph-autocapture"
							onClick={() =>
								trackDownload(
									"Salarisschalen",
									"https://www.dropbox.com/scl/fo/4l3yr6hj2jk75sr5d21ev/h?rlkey=6pl65bzs45wwv66koydkjr3i5&dl=1"
								)
							}
						>
							<ArrowDownTrayIcon
								className="mr-2 h-5 w-5 text-white"
								aria-hidden="true"
							/>
							Download salarisschalen
						</Button>
						<a
							href="https://help.dentiva.nl/nl/articles/8671656-werken-met-de-template-voor-je-eigen-salarishuis"
							className="flex hover:font-medium pt-3 text-black transition-all ph-autocapture"
							target="_blank"
							rel="noreferrer"
							onClick={() =>
								mixpanel.track(
									"📚 Toelichting voor salarishuis bekeken",
									{
										helpLink:
											"https://help.dentiva.nl/nl/articles/8671656-werken-met-de-template-voor-je-eigen-salarishuis",
									}
								)
							}
						>
							<QuestionMarkCircleIcon
								className="mr-2 h-5 w-5 text-blue-600 mt-0.5"
								aria-hidden="true"
							/>
							Bekijk de toelichting
						</a>
					</div>
					<div className="mt-auto mb-auto">
						<img
							src={excelPhoto}
							alt="Excel"
							className="w-72 h-auto"
						/>
					</div>
				</div>
				<div className="mt-8 px-12 py-12 bg-white shadow rounded-3xl">
					<h3 className="text-lg leading-6 font-semibold text-gray-900">
						KNMT Functieprofielen
					</h3>
					<p className="pt-3 text-gray-600 mb-8">
						Deze dien je aan je medewerker te geven.
					</p>
					<Button
						href="https://www.dropbox.com/scl/fo/6skcp987um4vafjv4cteo/h?rlkey=2s489ztorh9wrpqz738rbre6p&dl=1"
						classNames="flex w-fit pr-6 ph-autocapture"
						target="_blank"
						onClick={() =>
							trackDownload(
								"KNMT Functieprofielen",
								"https://www.dropbox.com/scl/fo/6skcp987um4vafjv4cteo/h?rlkey=2s489ztorh9wrpqz738rbre6p&dl=1"
							)
						}
					>
						<ArrowDownTrayIcon
							className="mr-2 h-5 w-5 text-white"
							aria-hidden="true"
						/>
						Download functieprofielen
					</Button>
				</div>
				<div className="mt-8 px-12 py-12 bg-white shadow rounded-3xl">
					<h3 className="text-lg leading-6 font-semibold text-gray-900">
						Addendum personeelsreglement
					</h3>
					<p className="pt-3 text-gray-600 mb-8">
						Het addendum moet standaard worden toegezonden met het
						personeelsreglement. Dat is belangrijk voor alle
						bestaande medewerkers. Alleen met het getekende addendum
						is het personeelsreglement voor de medewerker geldig.
						<br /> <br />
						Afwijkingen van het personeelsreglement in individuele
						gevallen kunnen ook in het addendum worden opgenomen.
						Als de afwijking standaard is, dan hoort deze in het
						personeelsreglement thuis.
					</p>
					<Button
						href="https://www.dropbox.com/sh/exus4t4d7fjscw0/AACdc2kBThnnD2jE5XnmOvABa?dl=1"
						classNames="flex w-fit pr-6 ph-autocapture"
						target="_blank"
						onClick={() =>
							trackDownload(
								"Addendum personeelsreglement",
								"https://www.dropbox.com/sh/exus4t4d7fjscw0/AACdc2kBThnnD2jE5XnmOvABa?dl=1"
							)
						}
					>
						<ArrowDownTrayIcon
							className="mr-2 h-5 w-5 text-white"
							aria-hidden="true"
						/>
						Download addendum
					</Button>
				</div>
				<div className="flex space-x-6">
					<div className="mt-8 px-12 py-12 bg-white shadow rounded-3xl">
						<h3 className="text-lg leading-6 font-semibold text-gray-900">
							Arbeidsovereenkomst: Keuzeoverzicht & Toelichting
						</h3>
						<p className="pt-3 text-gray-600 mb-8">
							Lees over de keuzes en hoe het model in elkaar
							steekt.
						</p>
						<Button
							href="https://www.dropbox.com/sh/ay1el844vkhcct4/AAD__14sczoSiVy5E2A0Lmqea?dl=0"
							classNames="flex w-fit pr-6 ph-autocapture"
							target="_blank"
							onClick={() =>
								trackDownload(
									"Arbeidsovereenkomst: Keuzeoverzicht & Toelichting",
									"https://www.dropbox.com/sh/ay1el844vkhcct4/AAD__14sczoSiVy5E2A0Lmqea?dl=0"
								)
							}
						>
							<ArrowDownTrayIcon
								className="mr-2 h-5 w-5 text-white"
								aria-hidden="true"
							/>
							Downloaden
						</Button>
					</div>
					<div className="mt-8 px-12 py-12 bg-white shadow rounded-3xl">
						<h3 className="text-lg leading-6 font-semibold text-gray-900">
							Personeelsreglement: Keuzeoverzicht & Toelichting
						</h3>
						<p className="pt-3 text-gray-600 mb-8">
							Lees over de keuzes en hoe het model in elkaar
							steekt.
						</p>
						<Button
							href="https://www.dropbox.com/sh/086rsbvmqyn4d67/AADZx9gtSDikVtUQ3mM_LaTBa?dl=1"
							classNames="flex w-fit pr-6 ph-autocapture"
							onClick={() =>
								trackDownload(
									"Personeelsreglement: Keuzeoverzicht & Toelichting",
									"https://www.dropbox.com/sh/086rsbvmqyn4d67/AADZx9gtSDikVtUQ3mM_LaTBa?dl=1"
								)
							}
						>
							<ArrowDownTrayIcon
								className="mr-2 h-5 w-5 text-white"
								aria-hidden="true"
							/>
							Downloaden
						</Button>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Downloads;
