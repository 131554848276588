import React from "react";

import { useUser } from "@clerk/clerk-react";
import { PopupButton } from "@typeform/embed-react";
import { toast } from "react-toastify";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

const RequestNewType = () => {
	const { user } = useUser();

	const hiddenFields = {
		user_id: user.id,
		name: `${user.firstName} ${user.lastName}`,
	};

	function handleSubmit() {
		const esc = new KeyboardEvent("keydown", {
			key: "Escape",
			code: "Escape",
			charCode: 27,
			keyCode: 27,
			view: window,
		});
		document.dispatchEvent(esc);

		toast.success("Aanvraag ingediend", {
			position: "bottom-center",
			theme: "light",
			icon: (
				<CheckCircleIcon
					className=" text-green-500"
					aria-hidden="true"
				/>
			),
		});
	}

	return (
		<PopupButton
			id="MdIGNmre"
			buttonText=""
			hidden={hiddenFields}
			onSubmit={handleSubmit}
		>
			<div className="text-blue-600 font-semibold flex hover:text-blue-700 duration-100 transition-colors">
				<QuestionMarkCircleIcon className="w-5 mr-1.5 ml-2" />
				Mis je iets? Laat het ons weten!
			</div>
		</PopupButton>
	);
};

export default RequestNewType;
