import React from "react";

const Spacer = () => {
	return (
		<div className="w-full pt-8">
			<span></span>
		</div>
	);
};

export default Spacer;
