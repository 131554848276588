import React from "react";
import DataTile from "./DataTile";

import emojiVeryGood from "../../img/emojiVeryGood.png";
import emojiGood from "../../img/emojiGood.png";
import emojiNeutral from "../../img/emojiNeutral.png";
import emojiBad from "../../img/emojiBad.png";

const NPSMeter = ({ score }) => {
	const getEmojiForScore = (score) => {
		if (score >= 50) {
			return <img src={emojiVeryGood} alt="extremely good" />; // extremely good
		} else if (score >= 20 && score < 50) {
			return <img src={emojiGood} alt="good" />; // good
		} else if (score >= 0 && score < 20) {
			return <img src={emojiNeutral} alt="neutral/average" />; // neutral/average
		} else if (score < 0) {
			return <img src={emojiBad} alt="bad" />; // bad
		} else {
			return <img src={emojiGood} alt="good" />; // good
		}
	};

	const emoji = getEmojiForScore(score);

	return <DataTile label="NPS score" data={score} emoji={emoji} />;
};

export default NPSMeter;
