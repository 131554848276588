import React from "react";
import GaugeChart from "react-gauge-chart";

import FormattedNumber from "../../shared/components/util/FormattedNumber";

const Gauge = ({ minValue = 0, maxValue = 5, currentValue, name }) => {
	let colorArray;
	if (currentValue <= maxValue && currentValue > 0.8 * maxValue) {
		colorArray = ["#37D035", "#2BAB14"];
	} else if (
		currentValue > 0.6 * maxValue &&
		currentValue <= 0.8 * maxValue
	) {
		colorArray = ["#d1d638", "#b4d638", "#b4d638", "#a9c73c", "#D3D3D3"];
	} else if (
		currentValue > 0.4 * maxValue &&
		currentValue <= 0.6 * maxValue
	) {
		colorArray = ["#E1C91A", "#E9B020", "#E9B020", "#D3D3D3", "#D3D3D3"];
	} else if (
		currentValue > 0.2 * maxValue &&
		currentValue <= 0.4 * maxValue
	) {
		colorArray = ["#f76b34", "#F15619", "#D3D3D3", "#D3D3D3", "#D3D3D3"];
	} else if (currentValue === 0) {
		colorArray = ["#D3D3D3"];
	} else {
		colorArray = ["#F15619", "#D3D3D3", "#D3D3D3", "#D3D3D3", "#D3D3D3"];
	}

	return (
		<div
			className={`gauge-denthr ${currentValue === 0 ? "opacity-75" : ""}`}
		>
			<GaugeChart
				id={`gauge-chart-${name}`}
				cornerRadius={50}
				nrOfLevels={5}
				colors={colorArray}
				percent={currentValue / maxValue}
			/>
			<p className="text-2xl font-bold text-center">
				<FormattedNumber data={currentValue} decimals={1} />
			</p>
		</div>
	);
};

export default Gauge;
// currentValue === 0
// 						? ["lightgray", "lightgray", "lightgray"]
// 						: ["#ed5139", "#F5CD19", "#35bf19"]
