import React from "react";
import { useField } from "formik";

const CheckboxGroup = ({ label, description, options, ...props }) => {
	// We're assuming that the value associated with this field in Formik's initialValues is an array.
	const [field = { value: [] }, meta, helpers] = useField(props.name);

	const handleCheckboxChange = (event) => {
		const currentValue = Array.isArray(field.value) ? field.value : [];
		if (event.target.checked) {
			helpers.setValue([...currentValue, event.target.value]);
		} else {
			helpers.setValue(
				currentValue.filter((item) => item !== event.target.value)
			);
		}
	};

	return (
		<div className={props.width ? "w-" + props.width : "w-full"}>
			<div
				className={`${props.className} mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2`}
			>
				<div className={"col-span-full"}>
					<label
						htmlFor={props.id || props.name}
						className="block text-sm font-medium leading-6
				text-gray-900 mb-3"
					>
						{label}
					</label>
					{description && (
						<p
							className="text-sm leading-5 text-gray-500 pb-5"
							dangerouslySetInnerHTML={{ __html: description }}
						></p>
					)}
					<div className="space-y-4">
						{options.map((option, index) => (
							<div
								key={index}
								className="relative flex items-start"
							>
								<label className="flex items-center">
									<input
										{...props}
										name={props.name}
										value={option.value}
										checked={
											Array.isArray(field.value) &&
											field.value.includes(option.value)
										}
										onChange={handleCheckboxChange}
										type="checkbox"
										id={`${option.value}`}
										className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded cursor-pointer"
									/>
									<div className="ml-3 text-sm">
										<span className="text-gray-700 cursor-pointer">
											{option.label}
										</span>
										{option.description && (
											<p
												id={`${option.value}-description`}
												className="text-gray-500 cursor-pointer"
											>
												{option.description}
											</p>
										)}
									</div>
								</label>
							</div>
						))}
						{meta.touched && meta.error && (
							<div className="error mt-2">{meta.error}</div>
						)}
						{meta.touched && meta.error && (
							<div className="error mt-2">{meta.error}</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckboxGroup;
