import { ArrowPathIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import useFetch from "../../shared/hooks/useFetch";

const RegenerateReport = () => {
	const { user } = useUser();
	const fetchReport = useFetch();
	const navigate = useNavigate();

	const [isGenerating, setIsGenerating] = useState(false);

	const handleRegenerateReport = async () => {
		try {
			toast(
				"Rapport opnieuw aan het genereren... Dit kan tot een minuut duren",
				{
					position: "bottom-center",
					theme: "light",
					hideProgressBar: false,
					duration: 60000,
					icon: null,
				}
			);

			setIsGenerating(true);

			await fetchReport(
				`/api/tevredenheid/report/${user.id}/regenerate`,
				{
					method: "POST",
				}
			);

			setIsGenerating(false);

			toast.success("Je nieuwe rapport staat klaar!", {
				position: "bottom-center",
				theme: "light",
				hideProgressBar: true,
				duration: 800,
				icon: (
					<CheckCircleIcon
						className={`text-green-500`}
						aria-hidden="true"
					/>
				),
			});

			navigate(0);
		} catch (error) {
			console.error("Error regenerating report:", error);
		}
	};

	return (
		<button onClick={handleRegenerateReport} disabled={isGenerating}>
			{isGenerating ? (
				<ArrowPathIcon className="ml-1 h-5 w-5 text-slate-400 -mb-1.5 animate-spin" />
			) : (
				<ArrowPathIcon className="ml-1 h-5 w-5 text-slate-400 -mb-1.5 hover:text-slate-500 hover:scale-110 duration-100" />
			)}
		</button>
	);
};

export default RegenerateReport;
