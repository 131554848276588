import React from "react";

const Subtitle = ({ title, description }) => {
	return (
		<div className="w-full px-2 mt-10 pt-8 border-t ">
			<h3 className="text-lg font-bold">{title}</h3>
			<p className="text-sm leading-5 text-gray-500 mt-1">
				{description}
			</p>
		</div>
	);
};

export default Subtitle;
