import React from "react";
import FormattedNumber from "../../shared/components/util/FormattedNumber";

const DataTile = ({ data, label, emoji, classNames }) => {
	return (
		<div className={classNames}>
			<p className="font-regular text-gray-600 text-2xl">{label}</p>
			<div className="flex">
				{emoji && <div className="pr-2 w-14 -ml-1">{emoji}</div>}
				{typeof data === "number" ? (
					<span className="text-black mt-1 text-4xl font-semibold">
						<FormattedNumber data={data} />
					</span>
				) : (
					<span className="text-black mt-1 text-3xl font-semibold">
						{data}
					</span>
				)}
			</div>
		</div>
	);
};

export default DataTile;
