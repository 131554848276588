import { useAuth } from "@clerk/clerk-react";
import { useCallback } from "react";
import { toast } from "react-toastify";

export default function useFetch() {
	const { getToken } = useAuth();
	const baseURL = process.env.REACT_APP_BACKEND_URL;

	const authenticatedFetch = useCallback(
		async (endpoint, { responseType = "json", ...init } = {}) => {
			const response = await fetch(`${baseURL}${endpoint}`, {
				credentials: "include",
				...init,
				headers: {
					Authorization: `Bearer ${await getToken()}`,
					...init.headers,
				},
			});

			if (!response.ok) {
				toast.error("Er is iets misgegaan, probeer het later opnieuw.");
				throw new Error("Network response was not ok");
			}

			switch (responseType) {
				case "json":
					return response.json();
				case "blob":
					return response;
				default:
					throw new Error(
						`Unsupported response type: ${responseType}`
					);
			}
		},
		[getToken]
	);

	return authenticatedFetch;
}
