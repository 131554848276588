import React, { useState } from "react";
import Markdown from "react-markdown";
import {
	ChevronDownIcon,
	ChevronUpIcon,
	ShieldExclamationIcon,
	SparklesIcon,
} from "@heroicons/react/20/solid";

import FormattedDate from "../../shared/components/util/FormattedDate";
import ReportFeedback from "./ReportFeedback";
import RegenerateReport from "./RegenerateReport";

import mixpanel from "mixpanel-browser";
import { useUser } from "@clerk/clerk-react";

const ReportAI = ({ report, setReport, createdAt }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const { user } = useUser();

	const toggleExpanded = () => {
		setIsExpanded(!isExpanded);
		mixpanel.track("🧐 AI Verbeterplan: Rapport gelezen", {
			distinct_id: user.id,
			report: report,
		});
	};

	return (
		<>
			<div className="sm:flex mb-4">
				<div className="flex">
					<SparklesIcon
						className="h-9 w-9 mr-2 text-blue-500"
						aria-hidden="true"
					/>
					<h1 className="text-3xl font-bold">AI-Verbeterplan</h1>
				</div>
				<span className="hidden sm:inline ml-4 px-3 py-1.5 bg-blue-50 border-blue-200 border text-blue-600 rounded-xl">
					Beta
				</span>
			</div>
			<div className="bg-gray-50 py-4 px-5 mb-6 rounded-xl border-gray-200 border sm:flex inline-block items-center">
				<ShieldExclamationIcon
					className="sm:h-8 sm:w-8 h-5 w-5 sm:mb-0 mb-2 mr-4 text-gray-400"
					aria-hidden="true"
				/>
				<p className="text-gray-700">
					Dit experimentele rapport is gegenereerd door artificiële
					intelligentie. Het kan fouten bevatten. Gaat er iets mis, of
					heb je andere feedback? Laat het ons weten via de chat
					rechtsonder.
				</p>
			</div>
			<div
				id="markdown"
				className={`report-content ${
					isExpanded ? "expanded" : "collapsed"
				}`}
			>
				<div className="inner">
					<Markdown>{report}</Markdown>
				</div>
			</div>
			<button
				onClick={toggleExpanded}
				className="cursor-pointer text-blue-600 font-semibold text-lg flex mt-4"
			>
				{isExpanded ? "Rapport verbergen" : "Lees rapport"}
				{isExpanded ? (
					<ChevronUpIcon className="h-5 w-5 ml-0.5 mt-1.5" />
				) : (
					<ChevronDownIcon className="h-5 w-5 ml-0.5 mt-1.5" />
				)}
			</button>
			{createdAt && (
				<p className="text-gray-600 text-sm pt-4">
					<FormattedDate date={createdAt} verb="gegenereerd" />{" "}
					{createdAt &&
						Date.now() - createdAt > 24 * 60 * 60 * 1000 && (
							<>
								<RegenerateReport />
							</>
						)}
					<span className="font-black mx-2 text-gray-400">·</span>
					<ReportFeedback report={report} />
				</p>
			)}
		</>
	);
};

export default ReportAI;
