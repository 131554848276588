import React, { useEffect } from "react";

import Modal from "react-modal";
import {
	CheckBadgeIcon,
	CheckCircleIcon,
	XMarkIcon,
} from "@heroicons/react/20/solid";
import { Widget } from "@typeform/embed-react";
import { useUser } from "@clerk/clerk-react";

const AskReviewModal = ({ isOpen, setIsOpen, fileType, fileID }) => {
	const { user } = useUser();

	const handleClose = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		setTimeout(() => {
			document.getElementById("downloadFile").click();
		}, 5000);
	}, []);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={handleClose}
				contentLabel="PDF Viewer"
				style={{
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.45)",
					},
					content: {
						borderRadius: "18px",
						border: "none",
						top: "10%",
						bottom: "10%",
						left: "25%",
						right: "25%",
					},
				}}
			>
				<div className="text-right pt-4 pr-4">
					<button onClick={handleClose}>
						<XMarkIcon className="w-7 h-7 text-gray-400 hover:text-gray-500 transition-colors" />
					</button>
				</div>
				<div className="px-10 pt-10 pb-6 text-center">
					<CheckCircleIcon className="w-16 h-16 text-green-500 mx-auto pb-2" />
					<h3 className="font-bold text-2xl mb-2">
						Helemaal goed, je {fileType} is klaar!{" "}
						<span className="font-medium">
							Hij wordt binnen ongeveer 10 seconden gedownload
							naar je computer...
						</span>
					</h3>
					<p className="pt-3 text-gray-600 text-lg">
						In de tussentijd... Invullen kost minder dan 20 seconden
						:)
					</p>
					<Widget
						id="ik8YdVSG"
						className="h-[400px]"
						hidden={{
							filetype: fileType,
							file_id: fileID,
							user_id: user.id,
							name: `${user.firstName} ${user.lastName}`,
						}}
					/>
				</div>
			</Modal>
		</>
	);
};

export default AskReviewModal;
