import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
	return (
		<Link to={props.link}>
			<div className="px-8 py-6 bg-white shadow rounded-2xl mb-6 hover:scale-105 hover:shadow-md ease-in">
				<h3 className="text-lg font-medium">{props.title}</h3>
				<p className="text-gray-500">{props.description}</p>
			</div>
		</Link>
	);
};

export default Card;
