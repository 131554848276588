import React, { useEffect } from "react";
import confetti from "canvas-confetti";

const Confetti = () => {
	useEffect(() => {
		const count = 200;
		const defaults = {
			origin: { y: 0.7 },
		};

		function fire(particleRatio, opts) {
			confetti({
				...defaults,
				...opts,
				particleCount: Math.floor(count * particleRatio),
			});
		}

		fire(0.25, {
			spread: 26,
			startVelocity: 80, // Increased
			gravity: 1.2, // Added
		});
		fire(0.2, {
			spread: 60,
			startVelocity: 80, // Increased
			gravity: 1.2, // Added
		});
		fire(0.35, {
			spread: 100,
			decay: 0.8, // Decreased
			scalar: 0.8,
			startVelocity: 80, // Increased
			gravity: 1.2, // Added
		});
		fire(0.1, {
			spread: 120,
			startVelocity: 80, // Increased
			decay: 0.8, // Decreased
			scalar: 1.2,
			gravity: 1.2, // Added
		});
		fire(0.1, {
			spread: 120,
			startVelocity: 80, // Increased
			gravity: 1.2, // Added
		});
	}, []);

	return <div></div>;
};

export default Confetti;
