import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import useFetch from "../../shared/hooks/useFetch";

import documentImage from "../../img/DocumentLoading.png";
import Container from "../../shared/components/UIElements/Container";
import ViewFileButton from "../components/ViewFileButton";
import Heading from "../../shared/components/UIElements/Heading";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import DownloadFileButton from "../components/DownloadFileButton";
import FormattedDate from "../../shared/components/util/FormattedDate";
import DuplicateFileButton from "../components/DuplicateFileButton";
import DeleteButton from "../components/DeleteButton";
import Confetti from "../../shared/components/util/Confetti";
import AskReviewModal from "../components/AskReviewModal";
import FieldTip from "../../forms/components/FieldTip";

const SingleContract = ({ fileType }) => {
	const [file, setFile] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isFromWizard, setIsFromWizard] = useState(false);
	const [askReview, setAskReview] = useState(false);

	const { fileId } = useParams();
	const fetch = useFetch();
	const location = useLocation();

	const endpoint = {
		contract: "/api/contracten",
		ovo: "/api/ovo",
		personeelsreglement: "/api/reglementen",
	};

	useEffect(() => {
		const apiEndpoint = endpoint[fileType];

		const fetchFiles = async () => {
			const data = await fetch(`${apiEndpoint}/${fileId}`);
			setFile(data);
			setIsLoading(false);
		};

		fetchFiles();
	}, [fetch]);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const fromWizard = searchParams.get("netGemaakt");
		const vraagReview = searchParams.get("vraagReview");

		if (vraagReview === "ja") {
			setAskReview(true);

			const urlWithoutQuery = window.location.href.split("?")[0];
			window.history.replaceState({}, document.title, urlWithoutQuery);
		}

		if (fromWizard === "ja") {
			setIsFromWizard(true);

			setTimeout(() => {
				const urlWithoutQuery = window.location.href.split("?")[0];
				window.history.replaceState(
					{},
					document.title,
					urlWithoutQuery
				);
			}, 1000);
		}
	}, []);

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<Container>
			{isFromWizard && <Confetti />}
			{askReview && (
				<AskReviewModal
					isOpen={askReview}
					setIsOpen={setAskReview}
					fileType={fileType}
					fileID={fileId}
				/>
			)}
			<div className="flex">
				<div className="w-1/3">
					<img
						src={documentImage}
						alt="Document"
						className="z-0 relative"
					/>
					<div className="-mt-20 z-0 relative mx-4">
						<ViewFileButton
							bg="dark"
							className={
								"w-full center transition ease-in-out duration-300 rounded-lg bg-blue-600 mt-5 hover:scale-105 px-6 py-3 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
							}
							fileID={fileId}
							fileType={fileType}
						/>
					</div>
				</div>
				<div className="w-2/3 px-20 pt-16 align-center">
					<p className="font-semibold text-gray-500 text-xl align-middle">
						{fileType === "personeelsreglement"
							? `Personeelsreglement ${file.info.praktijkNaam}`
							: fileType === "ovo"
							? `Overeenkomst van Opdracht (${file.info.praktijkNaam})`
							: `Arbeidsovereenkomst (${file.info.praktijkNaam})`}
					</p>
					<Heading>
						{(() => {
							switch (fileType) {
								case "personeelsreglement":
									return `Versie ${file.info.versie}`;
								case "ovo":
									return file.info.naamZZP;
								default:
									return `${file.info.werknemerVoorletters} ${file.info.werknemerAchternaam}`;
							}
						})()}
					</Heading>
					<p className="text-md text-gray-600 pt-6 pb-4">
						<FormattedDate date={file.dateCreated} />
					</p>
					<DownloadFileButton
						classNames="transition mr-4 ease-in-out duration-300 rounded-lg bg-blue-600 mt-5 hover:scale-105 px-6 py-3 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
						bg="dark"
						fileID={fileId}
						fileType={fileType}
					/>

					{fileType === "ovo" && (
						<div className="p-5 border-yellow-400 border shadow-sm shadow-yellow-100 rounded-xl bg-[#fffcdc49] items-center text-yellow-900 text-sm mt-6">
							<p className="mb-2">In deze overeenkomst zijn de artikelen met de
						voorwaarden die van belang zijn bij het bepalen of er
						sprake is van een dienstbetrekking, geel gemarkeerd. De 
						<strong> niet-gemarkeerde artikelen</strong> kunt u
						aanvullen en aanpassen voor uw eigen situatie, als dat
								niet in strijd is met de gemarkeerde artikelen.
							</p>
							<Link
								to={
									file.info.model === "2021"
										? "https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/themaoverstijgend/brochures_en_publicaties/voorbeeldovereenkomst_praktijkmedewerking_tandartsen_knmt_ant_en_vvaa"
										: "https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/themaoverstijgend/brochures_en_publicaties/modelovereenkomst_mondzorgpraktijk"
								}
								target="_blank"
								className="text-yellow-600 font-semibold hover:opacity-80"
							>
								Meer lezen
							</Link>
						</div>
					)}

					<div className="border-t pt-8 mt-10">
						<h4 className="font-semibold text-lg">
							Dit document als basis voor een nieuwe?
						</h4>
						<p className="text-md text-gray-600 pt-2">
							Maak via de knop hieronder een nieuw document aan op
							basis van deze keuzes.
						</p>
						<DuplicateFileButton
							fileID={fileId}
							fileType={fileType}
						/>
					</div>
				</div>
			</div>
			<div className="mt-20 pt-20 center border-t ">
				<DeleteButton fileID={fileId} fileType={fileType} />
				<p className="text-sm text-gray-600 pl-4">
					(dit kan niet ongedaan gemaakt worden)
				</p>
			</div>
		</Container>
	);
};

export default SingleContract;
