import React from "react";
import Container from "../../shared/components/UIElements/Container";
import FileList from "../components/FileList";
import Heading from "../../shared/components/UIElements/Heading";
import Button from "../../shared/components/FormElements/Button";
import { PlusIcon } from "@heroicons/react/20/solid";

const OVOs = () => {
	return (
		<Container>
			<div className="flex justify-between align-baseline pb-12">
				<Heading>Overeenkomsten van Opdracht</Heading>
				<Button to="/nieuw/ovo" classNames="flex items-center -mt-1">
					<PlusIcon className="w-5 h-5 mr-2 -ml-2" />
					Nieuwe OVO
				</Button>
			</div>
			<div className="xl:flex">
				<div className="xl:w-2/5 bg-slate-200 bg-opacity-50 px-10 py-8 rounded-3xl h-fit">
					<Heading size="3">Concepten</Heading>
					<p className="text-gray-500 text-base mt-1 mb-4">
						Ga verder waar je gebleven bent. We slaan je concepten
						tussentijds op.
					</p>
					<FileList fileType="ovo" status="draft" limit="5" />
				</div>
				<div className="xl:w-3/5 xl:pl-12 pt-8">
					<FileList fileType="ovo" />
				</div>
			</div>
		</Container>
	);
};

export default OVOs;
