import React from "react";

import Button from "../../shared/components/FormElements/Button";
import { PlusCircleIcon } from "@heroicons/react/20/solid";

const FormActions = ({
	currentStep,
	steps,
	setCurrentStep,
	formIsCompleted,
}) => {
	return (
		<>
			{currentStep === steps.length && !formIsCompleted && (
				<p className="center py-2 px-10 bg-orange-100 text-orange-600 inline mt-6 -mb-2 rounded-full w-fit">
					Vul eerst alle keuzes in voordat je het document aanmaakt. In het overzicht links staat een uitroepteken bij de stappen waar velden nog niet zijn ingevuld.
				</p>
			)}
			<div className="flex space-x-2 pt-4">
				{currentStep > 1 && (
					<Button
						onClick={(e) => {
							e.preventDefault();
							setCurrentStep((prev) => prev - 1);
						}}
						classNames="backbutton text-slate-600 bg-gray-200 hover:bg-gray-100"
						title="Vorige stap"
					>
						←
					</Button>
				)}
				{currentStep !== steps.length && steps.length > 1 ? (
					<Button
						onClick={(e) => {
							e.preventDefault();
							setCurrentStep((prev) => prev + 1);
						}}
						classNames="grow"
					>
						Volgende stap →
					</Button>
				) : (
					<Button
						type="submit"
						classNames="grow flex middle center"
						disabled={!formIsCompleted}
					>
						<PlusCircleIcon className="w-5 mr-2" />
						Maak document
					</Button>
				)}
			</div>
		</>
	);
};

export default FormActions;
