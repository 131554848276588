import React from "react";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

import useFetch from "../../shared/hooks/useFetch";

const DownloadFileButton = ({ fileID, fileType, classNames, bg = "light" }) => {
	const fetchDocument = useFetch();

	const endpoint = {
		contract: "/api/contracten",
		ovo: "/api/ovo",
		personeelsreglement: "/api/reglementen",
	};

	const downloadFile = async () => {
		toast(`Het ${fileType} wordt gedownload...`, {
			position: "bottom-center",
			theme: "light",
			icon: null,
			hideProgressBar: false,
			autoClose: 2000,
		});

		const uri = `${endpoint[fileType]}/download/${fileID}/`;

		try {
			const response = await fetchDocument(uri, { responseType: "blob" });

			const blob = await response.blob();

			const contentDisposition = response.headers.get(
				"Content-Disposition"
			);
			console.log(contentDisposition);
			let filename = "Document Contracttool.docx";

			if (contentDisposition) {
				const filenameMatch = contentDisposition.match(
					/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
				);
				if (filenameMatch && filenameMatch[1]) {
					filename = filenameMatch[1].replace(/['"]/g, "");
				}
			}

			const blobURL = window.URL.createObjectURL(blob);

			const tempLink = document.createElement("a");
			tempLink.href = blobURL;
			tempLink.setAttribute("download", filename);
			document.body.appendChild(tempLink);
			tempLink.click();
			document.body.removeChild(tempLink);
			window.URL.revokeObjectURL(blobURL);
		} catch (error) {
			console.error("There was an issue downloading the file:", error);
		}
	};

	return (
		<button
			onClick={downloadFile}
			className={`flex font-medium hover:scale-105 ${classNames}`}
			id="downloadFile"
		>
			<CloudArrowDownIcon
				className={`h-6 w-6 mr-2 ${
					bg === "light" ? "text-blue-600" : "text-white"
				}`}
			/>
			Downloaden
		</button>
	);
};

export default DownloadFileButton;
