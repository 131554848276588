import { SparklesIcon } from "@heroicons/react/20/solid";
import React from "react";
import Markdown from "react-markdown";

const AISummary = ({ text }) => {
	return (
		<div className="module-border-wrap mb-5 rounded-2xl">
			<div className="module rounded-[14px] p-7">
				<div className="flex pb-2">
					<SparklesIcon
						className="h-4 w-4 text-[#f03d64] mr-1.5 mt-0.5"
						aria-hidden="true"
					/>

					<p className="text-sm uppercase text-[#f03d64] font-bold ">
						AI samenvatting
					</p>
				</div>

				<div id="summary-markdown">
					<Markdown>{text}</Markdown>
				</div>
				<p className="text-sm text-gray-500 pt-5">
					Deze samenvatting is gegenereerd door AI en kan fouten
					bevatten.
				</p>
			</div>
		</div>
	);
};

export default AISummary;
