import React from "react";

import { UserProfile } from "@clerk/clerk-react";

import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import SignOutButton from "../components/SignOutButton";

const Profile = () => {
	return (
		<>
			<Container>
				<Heading>Je account</Heading>
				<a
					href="https://mijn.dentcourses.com/financieel/subscriptions/"
					target="_blank"
					rel="noreferrer"
					className="flex mt-6 text-blue-600 hover:text-blue-900 transition ease-in-out duration-300"
				>
					Beheer je abonnement
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						className="w-4 h-4 mt-1 ml-1"
					>
						<path
							fillRule="evenodd"
							d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z"
							clipRule="evenodd"
						/>
						<path
							fillRule="evenodd"
							d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z"
							clipRule="evenodd"
						/>
					</svg>
				</a>
				<div className="mt-profile">
					<UserProfile
						localization={{
							userProfile: {
								start: {
									dangerSection: {
										deleteAccountTitle: "Verwijderen",
									},
								},
							},
						}}
						appearance={{
							elements: {
								card: {
									boxShadow: "none",
									marginLeft: "-25px",
									backgroundColor: "transparent",
								},
								header: "hidden",
								profileSection__activeDevices: "hidden",
								profileSectionPrimaryButton__emailAddresses:
									"hidden",
								navbar: "hidden",
								formFieldInput:
									"rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6",
								formButtonPrimary:
									"bg-blue-600 hover:bg-blue-700 hover:scale-105 transition ease-in-out duration-300 rounded-lg normal-case text-base shadow-sm font-semibold tracking-normal py-3 text-white",
							},
						}}
					/>
				</div>
				<SignOutButton />
			</Container>
		</>
	);
};

export default Profile;
