import React, { Suspense, useEffect } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import { SignedIn, SignedOut, useUser } from "@clerk/clerk-react";
import { useIntercom } from "react-use-intercom";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";

// Pages
import Dashboard from "./shared/pages/Dashboard";
import Wizard from "./forms/pages/Wizard";
import SignUpPage from "./users/pages/SignupPage";
import LoginPage from "./users/pages/LoginPage";
import Profile from "./users/pages/Profile";
import Personeelsreglementen from "./files/pages/Personeelsreglementen";
import Contracten from "./files/pages/Contracten";
import Webinar from "./shared/pages/Webinar";
import OVOs from "./files/pages/OVOs"; // Add this import

// Components
import Sidebar from "./shared/components/Navigation/Sidebar";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";
import Downloads from "./shared/pages/Downloads";
import SingleContract from "./files/pages/SingleContract";
import Teamtevredenheid from "./tevredenheid/pages/Teamtevredenheid";
import ScrollToTop from "./shared/components/util/ScrollToTop";

function App() {
	const { boot } = useIntercom();
	const { user } = useUser();

	const routesWithSidebar = [
		<Route path="/" element={<Dashboard />} />,
		<Route path="/profiel" element={<Profile />} />,
		<Route
			path="/personeelsreglementen"
			element={<Personeelsreglementen />}
		/>,
		<Route path="/contracten" element={<Contracten />} />,
		<Route path="/ovos" element={<OVOs />} />, // Add this new route
		<Route
			path="/contract/:fileId"
			element={<SingleContract fileType="contract" />}
		/>,
		<Route
			path="/ovo/:fileId"
			element={<SingleContract fileType="ovo" />}
		/>,
		<Route
			path="/personeelsreglement/:fileId"
			element={<SingleContract fileType="personeelsreglement" />}
		/>,
		<Route path="/downloads" element={<Downloads />} />,
		<Route path="/webinar" element={<Webinar />} />,
		<Route path="/teamtevredenheid" element={<Teamtevredenheid />} />,
		<Route path="*" element={<Navigate to="/" />} />,
	];

	const routesWithoutSidebar = [
		<Route path="/nieuw/:fileType" element={<Wizard />} />,
	];

	const routesSignedout = [
		<Route path="/wachtwoord" element={<SignUpPage />} />,
		<Route path="/inloggen" element={<LoginPage />} />,
		<Route path="*" element={<Navigate to="/inloggen" />} />,
	];

	useEffect(() => {
		if (user) {
			const intercomUser = {
				user_id: user.id,
				email: user.primaryEmailAddress.emailAddress,
				name: `${user.firstName} ${user.lastName}`,
			};

			boot(intercomUser);

			if (process.env.REACT_APP_STAGING !== "true") {
				Sentry.init({
					dsn: "https://a140f0b44169c8012f01f757631ede0a@o4506249120579584.ingest.sentry.io/4506400231849984",
					integrations: [
						new Sentry.BrowserTracing(),
						// new Sentry.Replay(),
					],
					// Performance Monitoring
					tracesSampleRate: 1.0, //  Capture 100% of the transactions
					// Session Replay
					// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
				});
				Sentry.setUser({
					id: user.id,
					email: user.primaryEmailAddress.emailAddress,
				});
			}

			mixpanel.init("d9c944ae9bcb22855a575192b96d5608", {
				debug: true,
				track_pageview: true,
				persistence: "localStorage",
			});
			mixpanel.identify(user.id);
		}
	}, [user]);

	return (
		<Router>
			<SignedIn>
				<Suspense fallback={<LoadingSpinner />}>
					<ScrollToTop />
					<Routes>
						{routesWithoutSidebar}
						{routesWithSidebar.map((route, idx) => (
							<Route
								key={idx}
								path={route.props.path}
								element={
									<Sidebar>{route.props.element}</Sidebar>
								}
							/>
						))}
					</Routes>
				</Suspense>
			</SignedIn>
			<SignedOut>
				<Routes>{routesSignedout}</Routes>
			</SignedOut>
		</Router>
	);
}

export default App;
