import React from "react";
import { Formik, FieldArray, Form } from "formik";
import * as Yup from "yup";
import { TrashIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, PlusIcon } from "@heroicons/react/20/solid";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";

import useFetch from "../../shared/hooks/useFetch";

import Input from "../../shared/components/FormElements/Input";
import Select from "../../shared/components/FormElements/Select";
import Button from "../../shared/components/FormElements/Button";

const InviteTeamForm = ({ closeModal, hasMembers, setHasMembers }) => {
	const inviteMembers = useFetch();
	const { user } = useUser();

	const initialValues = {
		teamMembers: [
			{
				firstName: "",
				lastName: "",
				email: "",
				function: "",
			},
		],
	};

	const validationSchema = Yup.object().shape({
		teamMembers: Yup.array().of(
			Yup.object().shape({
				firstName: Yup.string().required("Vul de voornaam in"),
				lastName: Yup.string().required("Vul de achternaam in"),
				email: Yup.string()
					.email("Dit e-mailadres lijkt niet te kloppen")
					.required("Vul het e-mailadres in"),
				function: Yup.string().required("Vul de functie in"),
			})
		),
	});

	const handleSubmit = async (values) => {
		toast.success(
			`Gelukt! Je hebt ${values.teamMembers.length} teamleden uitgenodigd`,
			{
				position: "bottom-center",
				theme: "light",
				hideProgressBar: true,
				autoClose: 2000,
				icon: (
					<CheckCircleIcon
						className=" text-green-500"
						aria-hidden="true"
					/>
				),
			}
		);

		const contactInfo = {
			praktijkNaam: user.unsafeMetadata.initialPraktijkNaam,
			praktijkhouder: `${user.firstName} ${user.lastName}`,
			userId: user.id,
			...values,
		};
		try {
			await inviteMembers("/api/tevredenheid/invite", {
				method: "POST",
				body: JSON.stringify(contactInfo),
				headers: {
					"Content-Type": "application/json",
				},
			});

			closeModal();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({ values, handleChange, handleSubmit }) => (
				<Form
					onSubmit={handleSubmit}
					onBlur={() => {
						if (!hasMembers) {
							setHasMembers(true);
						}
					}}
				>
					<FieldArray name="teamMembers">
						{({ push, remove }) => (
							<>
								{values.teamMembers.map((teamMember, index) => (
									<div
										key={index}
										className="border-b-2 pb-10 border-gray-100"
									>
										<p className="text-black font-semibold pl-2 pt-6">
											Teamlid {index + 1}
										</p>
										<div className="flex">
											<div className="w-1/2">
												<Input
													label="Voornaam"
													name={`teamMembers[${index}].firstName`}
													value={teamMember.firstName}
													onChange={handleChange}
												/>
											</div>
											<div className="w-1/2">
												<Input
													label="Achternaam"
													name={`teamMembers[${index}].lastName`}
													value={teamMember.lastName}
													onChange={handleChange}
												/>
											</div>
										</div>

										<Input
											label="E-mailadres"
											name={`teamMembers[${index}].email`}
											value={teamMember.email}
											onChange={handleChange}
										/>
										<div className="w-1/2">
											<Select
												label="Functie"
												name={`teamMembers[${index}].function`}
												value={teamMember.function}
												onChange={handleChange}
												options={[
													"Tandarts",
													"Mondhygiënist",
													"Praktijkmanager",
													"Assistente",
													"Preventieassistente",
													"Ortho",
													"Balieassistente",
													"Paropreventie",
													"Overige",
												]}
											/>
										</div>
										{index > 0 && (
											<button
												type="button"
												onClick={() => remove(index)}
												className="ml-1.5 mt-4 flex text-sm font-medium hover:scale-105 w-fit"
											>
												<TrashIcon className="h-5 w-5 mr-2 text-red-600" />
												<span className="text-gray-600 hover:text-black">
													Teamlid verwijderen
												</span>
											</button>
										)}
									</div>
								))}
								<Button
									type="button"
									onClick={() =>
										push({
											firstName: "",
											lastName: "",
											email: "",
											function: "",
										})
									}
									classNames="flex font-medium bg-white  hover:bg-white border-2 border-blue-600 hover:border-blue-600 hover:scale-105 w-fit"
								>
									<PlusIcon className="h-6 w-6 mr-2 text-blue-600" />
									<span className="text-blue-600">
										Nog een teamlid
									</span>
								</Button>
							</>
						)}
					</FieldArray>
					<Button
						classNames="flex border-2 border-blue-600 mr-2 w-full text-center center hover:border-blue-500"
						type="submit"
					>
						<UserPlusIcon className="h-6 w-6 mr-2 text-white" />
						{values.teamMembers.length === 1
							? "Teamlid uitnodigen"
							: `Deze ${values.teamMembers.length} teamleden
						uitnodigen`}
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default InviteTeamForm;
