import React from "react";

import Avatar from "boring-avatars";

const ProfileImage = (props) => {
	return (
		<Avatar
			size={40}
			variant="beam"
			colors={["#FF005B", "#2563EB", "#0C3AA3", "#FF7D10", "#FFB238"]}
			name={props.name || "Matthijs Tolmeijer"}
		/>
	);
};

export default ProfileImage;
