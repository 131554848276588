import React from "react";
import Container from "../components/UIElements/Container";
import Heading from "../components/UIElements/Heading";

const Webinar = () => {
	return (
		<>
			<Container>
				<div className="pb-10 mb-12 border-b border-gray-200">
					<Heading>
						Webinar: Arbeidsovereenkomsten & Personeelsreglement
					</Heading>
					<p className="text-gray-600 pt-2">
						Dit is de replay van het webinar, die je als abbonee van
						DentHR kunt terugkijken.
					</p>
				</div>
				<div className="mt-8">
					<div
						style={{
							padding: "56.25% 0 0 0",
							position: "relative",
						}}
					>
						{" "}
						<iframe
							src="https://player.vimeo.com/video/887215513?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
							frameBorder="0"
							allow="autoplay; fullscreen; picture-in-picture"
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
								borderRadius: "17px",
								boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.2)",
							}}
							title="Webinar Arbeidsovereenkomsten"
						></iframe>
					</div>
					<script src="https://player.vimeo.com/api/player.js"></script>
				</div>
			</Container>
		</>
	);
};

export default Webinar;
