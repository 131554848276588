import React, { useState } from "react";

import Modal from "react-modal";
import { XMarkIcon } from "@heroicons/react/20/solid";
import InviteTeamForm from "./InviteTeamForm";

const InviteTeamModal = ({ isOpen, setIsOpen }) => {
	const [hasMembers, setHasMembers] = useState(false);

	const handleClose = () => {
		if (hasMembers) {
			window.confirm(
				"Weet je zeker dat je het venster wilt sluiten? Alle ingevulde gegevens gaan verloren."
			) && setIsOpen(false);
		} else {
			setIsOpen(false);
		}
	};
	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={handleClose}
				contentLabel="PDF Viewer"
				style={{
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.45)",
					},
					content: {
						borderRadius: "18px",
						border: "none",
						top: "10%",
						bottom: "10%",
						left: "25%",
						right: "25%",
					},
				}}
			>
				<div className="text-right pt-4 pr-4">
					<button onClick={handleClose}>
						<XMarkIcon className="w-7 h-7 text-gray-400 hover:text-gray-500 transition-colors" />
					</button>
				</div>
				<div className="px-10 pt-3 pb-6">
					<h3 className="font-bold text-2xl mb-2 pl-2">
						Nodig je teamleden uit
					</h3>
					<div>
						<InviteTeamForm
							closeModal={() => setIsOpen(false)}
							setHasMembers={setHasMembers}
							hasMembers={hasMembers}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default InviteTeamModal;
