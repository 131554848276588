import React from "react";

import SignInForm from "../components/SignInForm";

import AuthFooter from "../components/AuthFooter";
import AuthPromo from "../components/AuthPromo";
import LogoMark from "../../shared/components/Branding/LogoMark";
import Heading from "../../shared/components/UIElements/Heading";

const LoginPage = () => {
	return (
		<div className="bg-white h-screen grid lg:grid-cols-2">
			<div className="grid content-between px-12 sm:px-24">
				<LogoMark className="mt-12" />

				<div>
					<div className="pb-12">
						<Heading>Welkom terug!</Heading>
						<p className="pt-4 text-gray-700">
							Log in om door te gaan naar DentHR
						</p>
					</div>

					<SignInForm />
					<p className="pt-6 sm:center text-sm text-gray-800">
						Heb je nog geen account?{"  "}
						<a
							href="https://dentiva.nl/contract"
							className="text-blue-500 font-semibold hover:text-blue-600 ml-1"
							target="_blank"
						>
							Krijg toegang tot DentHR
						</a>
					</p>
				</div>

				<div className="mb-12 sm:flex justify-between">
					<AuthFooter />
				</div>
			</div>
			<div className="bg-blue-500 my-8 mr-8 rounded-2xl p-32 text-white hidden lg:block">
				<AuthPromo />
			</div>
		</div>
	);
};

export default LoginPage;
