import React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
	const { classNames, ...rest } = props;

	const baseClasses =
		classNames +
		" transition ease-in-out duration-300 rounded-lg bg-blue-600 mt-5 hover:scale-105 px-6 py-3 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600";

	if (props.href && !props.onClick) {
		return (
			<a className={baseClasses} href={props.href} target={props.target}>
				{props.children}
			</a>
		);
	} else if (props.href && props.onClick) {
		return (
			<a
				className={baseClasses}
				href={props.href}
				target={props.target}
				onClick={props.onClick}
			>
				{props.children}
			</a>
		);
	} else if (props.to) {
		return (
			<Link to={props.to} className={baseClasses}>
				{props.children}
			</Link>
		);
	} else {
		return (
			<button
				className={`${baseClasses} ${
					props.disabled
						? "opacity-70 bg-gray-400 cursor-not-allowed"
						: ""
				}`}
				documentType={props.documentType}
				onClick={
					props.disabled
						? (e) => {
								e.preventDefault();
								alert("Je hebt nog niet alle velden ingevuld");
						  }
						: props.onClick
				}
				disabled={props.disabled}
				{...rest}
			>
				{props.children}
			</button>
		);
	}
};

export default Button;
