import React from "react";

import { SparklesIcon } from "@heroicons/react/24/solid";
import Heading from "../../shared/components/UIElements/Heading";

const AuthPromo = () => {
	return (
		<>
			<SparklesIcon className="h-10 w-auto mb-4 text-blue-200" />
			<Heading className="text-white leading-10">
				Maak in een handomdraai contracten met DentHR
			</Heading>
			<p className="text-blue-100 mt-5 leading-7">
				Genereer je personeelsreglement en arbeidsovereenkomsten voor de
				praktijk met DentHR. Bekijk ook de online training en uitleg
				binnen de tool. En ga aan de slag met het online
				teamtevredenheidsonderzoek (inclusief slimme AI-analyse).
			</p>
		</>
	);
};

export default AuthPromo;
