import React, { useEffect } from "react";

const FormValidator = ({ formik, setValues }) => {
	useEffect(() => {
		setValues(formik.values);
	}, [formik.values]);
	return <></>;
};

export default FormValidator;
