import React, { useState } from "react";
import Modal from "react-modal";
import { ArrowUpRightIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Markdown from "react-markdown";
import mixpanel from "mixpanel-browser";
import { useUser } from "@clerk/clerk-react";

import Lightbulb from "../../img/Lightbulb.png";

const FieldTip = ({ question, preview, fullText }) => {
	const [isOpen, setIsOpen] = useState(false);

	const { user } = useUser();

	function openModal(e) {
		e.preventDefault();
		setIsOpen(true);
		mixpanel.track("💡 Tip popup geopend", {
			distinct_id: user.id,
			vraag: question,
			preview: preview,
			volledigeTekst: fullText,
		});
	}

	return (
		<>
			<div className="ml-2 mt-3 flex p-4 border-yellow-400 border shadow-sm shadow-yellow-100 rounded-xl bg-[#fffcdc49] pr-7 items-center mb-2 items-start">
				<img src={Lightbulb} className="w-6 h-6 mr-2 mt-0.5" alt="" />

				<p className="text-sm text-yellow-950">
					<Markdown>{preview}</Markdown>
					{fullText && (
						<button
							className="text-yellow-600 font-medium flex pt-1 hover:text-yellow-700 transition-colors duration-200"
							onClick={openModal}
						>
							Lees verder
							<ArrowUpRightIcon className="w-4 h-4 mt-0.5 ml-0.5" />
						</button>
					)}
				</p>
			</div>
			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				contentLabel="PDF Viewer"
				style={{
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.45)",
					},
					content: {
						borderRadius: "26px",
						border: "none",
						top: "17%",
						bottom: "17%",
						left: "25%",
						right: "25%",
					},
				}}
			>
				<div className="text-right pt-4 pr-4 sticky top-1">
					<button onClick={() => setIsOpen(false)}>
						<XMarkIcon className="w-7 h-7 text-gray-400 hover:text-gray-500 transition-colors" />
					</button>
				</div>
				<div className="px-10 pt-3 pb-6 mt-tipmodal">
					<p className="uppercase font-bold text-sm text-slate-500 flex tracking-wide">
						<img
							src={Lightbulb}
							className="w-6 h-6 -mt-0.5 mr-1 -ml-1.5"
							alt=""
						/>
						Toelichting
					</p>
					<p className="font-bold text-2xl pt-2 text-black">
						{question}
					</p>
					<p className="pt-6">
						<div className="inner tipModal">
							<Markdown>{fullText}</Markdown>
						</div>
					</p>
				</div>
			</Modal>
		</>
	);
};

export default FieldTip;
