import React, { useState } from "react";

import { UserPlusIcon } from "@heroicons/react/24/outline";

import Button from "../../shared/components/FormElements/Button";
import InviteTeamModal from "./InviteTeamModal";

const InviteTeamButton = () => {
	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	};

	return (
		<>
			<Button
				classNames="flex border-2 hover:border-blue-500 border-blue-600 mr-2"
				onClick={openModal}
			>
				<UserPlusIcon className="h-6 w-6 mr-2 text-white" />
				Teamleden uitnodigen
			</Button>
			<InviteTeamModal isOpen={isOpen} setIsOpen={setIsOpen} />
		</>
	);
};

export default InviteTeamButton;
